<template>
  <v-container>
    <v-row v-if="loading">
      <v-col>
        <h3 class="text-center">
          <v-progress-circular indeterminate class="ml-3" color="primary" />
          Please be patient, this may take a while.
        </h3>
      </v-col>
    </v-row>

    <v-alert
      v-show="error"
      transition="fade-transition"
      color="error"
      type="error"
      style="z-index: 2; position: fixed; right: 15px; min-width: 300px"
    >
      {{ errorMessage }}
    </v-alert>

    <v-alert
      v-show="formIsSubmitted"
      transition="fade-transition"
      color="success"
      type="success"
      style="z-index: 2; position: fixed; right: 15px; min-width: 300px"
    >
      Thank you! The form has been submitted
    </v-alert>

    <v-row v-if="!loading" justify="center">
      <v-col xs="12" xl="6">
        <v-row>
          <v-col>
            <p class="text-h4">
              <v-icon class="text-h4"> mdi-domain </v-icon>
              Mortgage Company KYC
            </p>

            <p class="text-subtitle-1 mb-0">
              Corporate Lender Onboarding: Know Your Client (KYC) Form
            </p>

            <p class="text-subtitle-2 mb-0">
              Kindly provide the following information to facilitate the
              onboarding process. This information will enable us to understand
              your company's mortgage lending operations, and investment
              preferences.
            </p>
          </v-col>
        </v-row>

        <v-row v-if="formIsSubmitting">
          <v-col>
            <h3 class="text-center">
              <v-progress-circular indeterminate class="ml-3" color="primary" />
              Please be patient, this may take a while.
            </h3>
          </v-col>
        </v-row>

        <v-row v-if="!formIsSubmitting">
          <v-col>
            <v-stepper v-model="e1" vertical>
              <div v-for="(section, index) in formData.frm_sects" :key="index">
                <v-stepper-step
                  :complete="
                    section.title === 'Corporation Sophisticated Investor'
                      ? section.frm_qs.some(
                          (question) => question.selected_choices.length > 0
                        )
                      : section.is_valid
                  "
                  :step="index + 1"
                >
                  <span :class="e1 === index + 1 ? 'green--text' : ''">
                    {{ section.title }}
                    {{
                      section.title === "Corporation Sophisticated Investor"
                        ? "( if applicable )"
                        : ""
                    }}
                  </span>
                </v-stepper-step>

                <v-stepper-content :step="index + 1">
                  <v-form v-model="section.is_valid">
                    <v-card class="mb-5">
                      <v-card-text>
                        <div
                          v-for="question in section.frm_qs"
                          :key="question.order"
                        >
                          <p class="text-body-1">{{ question.title }}</p>

                          <v-text-field
                            v-if="question.type === 'date'"
                            v-model="question.answer"
                            :rules="[rules.required]"
                            type="date"
                            outlined
                          />

                          <v-text-field
                            v-if="question.type === 'number'"
                            v-model="question.answer"
                            :rules="
                              question.title === 'Phone Number'
                                ? [rules.phone]
                                : [rules.required]
                            "
                            type="number"
                            outlined
                          />

                          <v-text-field
                            v-if="question.type === 'short text'"
                            v-model="question.answer"
                            :rules="
                              question.title === 'Email'
                                ? [rules.email]
                                : [rules.required]
                            "
                            outlined
                          />

                          <v-textarea
                            v-if="question.type === 'long text'"
                            v-model="question.answer"
                            :rules="[rules.required]"
                            auto-grow
                            outlined
                          />

                          <v-radio-group
                            v-if="question.type === 'single choice'"
                            v-model="question.answer"
                            :rules="[rules.required]"
                          >
                            <v-radio
                              v-for="option in question.frm_opts"
                              :key="option.order"
                              :label="option.choice"
                              :value="option.choice"
                            />
                          </v-radio-group>

                          <div v-if="question.type === 'multiple choice'">
                            <v-checkbox
                              v-model="question.selected_choices"
                              v-for="option in question.frm_opts"
                              :key="option.order"
                              :label="option.choice"
                              :value="option.id"
                              dense
                            />
                          </div>

                          <!-- follow up questions for response yes -->
                          <div
                            v-if="
                              question.with_follow_up_question &&
                              question.answer === 'Yes'
                            "
                            class="bordered rounded-lg pa-5 mb-5"
                          >
                            <div
                              v-for="fq_1 in question.frm_qs"
                              :key="fq_1.order"
                            >
                              <p class="text-body-1">{{ fq_1.title }}</p>

                              <v-text-field
                                v-if="fq_1.type === 'short text'"
                                v-model="fq_1.answer"
                                :rules="[rules.required]"
                                outlined
                              />

                              <v-textarea
                                v-if="fq_1.type === 'long text'"
                                v-model="fq_1.answer"
                                :rules="[rules.required]"
                                auto-grow
                                outlined
                              />
                            </div>
                          </div>

                          <!-- follow up questions for response others-->
                          <div
                            v-if="
                              question.with_follow_up_question &&
                              question.answer === 'Others'
                            "
                            class="bordered rounded-lg pa-5 mb-5"
                          >
                            <div
                              v-for="fq_1 in question.frm_qs"
                              :key="fq_1.order"
                            >
                              <p class="text-body-1">{{ fq_1.title }}</p>

                              <v-text-field
                                v-if="fq_1.type === 'short text'"
                                v-model="fq_1.answer"
                                :rules="[rules.required]"
                                outlined
                              />
                            </div>
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>

                    <v-btn
                      v-if="formData.frm_sects.length !== index + 1"
                      color="primary"
                      @click="e1 = index + 2"
                      :disabled="!section.is_valid"
                    >
                      Continue
                    </v-btn>

                    <v-btn
                      v-if="formData.frm_sects.length === index + 1"
                      color="primary"
                      :disabled="!section.is_valid || formIsSubmitting"
                      @click="handle_submit_btn"
                    >
                      Submit
                    </v-btn>

                    <v-btn v-if="index + 1 !== 1" @click="e1 = index" text>
                      back
                    </v-btn>
                  </v-form>
                </v-stepper-content>
              </div>
            </v-stepper>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn @click="$router.go(-1)" :disabled="formIsSubmitting">
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from "@/plugins/API";

export default {
  name: "Mortgage_Company",

  props: [
    "form_unique_id",
    "company_id",
    "user_id",
    "view_type",
    "form_access_token",
  ],

  data: () => ({
    loading: false,
    error: false,
    formIsSubmitting: false,
    formIsSubmitted: false,
    is_update_view: false,
    errorMessage: "",
    e1: 1,

    rules: {
      required: (value) => !!value || "Required.",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
      phone: (value) =>
        (value && value.length == 10) ||
        "Phone number must be 10 digits no spaces or characters (format 7803334444)",
    },

    formData: {
      title: "Mortgage Company",
      description: "",
      unique_id: "mortgage-company-kyc-2023-v1",

      frm_sects: [
        {
          order: 1,
          title: "Company Details",
          description: "",
          is_valid: false,

          frm_qs: [
            {
              order: 1,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title: "What is the legal name of your company?",
              description: "",
              type: "short text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 2,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title: "In which province was your company formed?",
              description: "",
              type: "short text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 3,
              is_a_follow_up_question: false,
              with_follow_up_question: true,
              answer: "",
              title: "What is the type of your company?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                {
                  order: 1,
                  choice: "Mortgage Investment Corporation (MIC)",
                },
                {
                  order: 2,
                  choice: "Mortgage Investment Entity (MIE)",
                },
                {
                  order: 3,
                  choice: "Mortgage Brokerage",
                },
                {
                  order: 4,
                  choice: "Others",
                },
              ],

              frm_qs: [
                {
                  order: 1,
                  is_a_follow_up_question: true,
                  with_follow_up_question: false,
                  answer: "",
                  title: "Please specify",
                  description: "",
                  type: "short text",
                  selected_choices: [],
                  frm_opts: [],
                  frm_qs: [],
                },
              ],
            },

            {
              order: 4,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title: "What is your company's total assets under management?",
              description: "",
              type: "short text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 5,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title:
                "Has your company or any of its officers ever been subject to legal or regulatory sanctions related to its lending activities?",
              description: "",
              type: "date",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 6,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title: "What is your position at the company?",
              description: "",
              type: "long text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },
          ],
        },

        {
          order: 2,
          title: "Corporation Sophisticated Investor",
          is_valid: false,
          description: "",

          frm_qs: [
            {
              order: 1,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title:
                "Does your corporation fit into any of the following categories? (Please check all that apply)",
              description: "",
              type: "multiple choice",
              selected_choices: [],

              frm_opts: [
                {
                  order: 1,
                  choice:
                    "A person or entity with net assets of at least $5 million as reflected in its most recently-prepared financial statements and can provide written confirmation of this to the brokerage.",
                },
                {
                  order: 2,
                  choice:
                    "A person or entity registered as an adviser or dealer under the Securities Act.",
                },
                {
                  order: 3,
                  choice:
                    "A person or entity registered under securities legislation in another province or territory of Canada.",
                },
                {
                  order: 4,
                  choice: "A brokerage acting on its own behalf.",
                },
                {
                  order: 5,
                  choice: "A financial institution.",
                },
                {
                  order: 6,
                  choice:
                    "A corporation that is a subsidiary of a person or entity, a Crown entity, a brokerage, or a financial institution.",
                },
                {
                  order: 7,
                  choice:
                    "A corporation that is an approved lender under the National Housing Act (Canada).",
                },
                {
                  order: 8,
                  choice:
                    "The Crown in right of Ontario, Canada or any province or territory of Canada.",
                },
                {
                  order: 9,
                  choice:
                    "An administrator or trustee of a registered pension plan.",
                },
                {
                  order: 10,
                  choice:
                    "A person or entity in respect of which all of the owners of interests, other than the owners of voting securities required by law to be owned by directors, are persons or entities described above.",
                },
              ],

              frm_qs: [],
            },
          ],
        },

        {
          order: 3,
          title: "Company Licensing",
          is_valid: false,
          description: "",

          frm_qs: [
            {
              order: 1,
              is_a_follow_up_question: false,
              with_follow_up_question: true,
              answer: "",
              title: "Is the company licensed to deal in mortgages?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                {
                  order: 1,
                  choice: "Yes",
                },
                {
                  order: 2,
                  choice: "No",
                },
              ],

              frm_qs: [
                {
                  order: 1,
                  is_a_follow_up_question: true,
                  with_follow_up_question: false,
                  answer: "",
                  title:
                    "Please list the provinces where the company is licensed and the corresponding license numbers:",
                  description: "",
                  type: "long text",
                  selected_choices: [],

                  frm_opts: [],

                  frm_qs: [],
                },
              ],
            },

            {
              order: 2,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title:
                "Has the license ever been cancelled due to disciplinary actions by a regulatory body?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                {
                  order: 1,
                  choice: "Yes",
                },
                {
                  order: 2,
                  choice: "No",
                },
              ],

              frm_qs: [],
            },

            {
              order: 3,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title:
                "Is the corporation lending money on the behalf of other individuals and taking a lender fee?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                {
                  order: 1,
                  choice: "Yes",
                },
                {
                  order: 2,
                  choice: "No",
                },
              ],

              frm_qs: [],
            },
          ],
        },
      ],
    },
  }),

  methods: {
    async handle_submit_btn() {
      try {
        this.formIsSubmitting = true;
        // console.log(
        //   "This formData should be sending to the backend: ",
        //   this.formData
        // );

        if (this.view_type === "logged_in") {
          if (this.is_update_view) {
            const response = await API().patch(`api/form/update_kyc_response`, {
              form_data: this.formData,
              unique_id: this.form_unique_id,
            });

            console.log(response);
          }
          if (!this.is_update_view) {
            const response = await API().post(`api/form/create_kyc_response`, {
              form_data: this.formData,
              company_id: this.company_id,
              user_id: this.user_id,
              unique_id: this.form_unique_id,
              account_id: Number(this.$route.query.account_id),
            });

            console.log(response);
          }

          this.formIsSubmitted = true;

          setTimeout(() => {
            this.formIsSubmitted = false;
          }, 2000);

          setTimeout(() => {
            this.$router.go(-1);
          }, 3000);
        }

        if (this.view_type === "form_access_link") {
          const response = await API().post(
            `api/form-access-link/create_kyc_response`,
            {
              form_data: this.formData,
              company_id: this.company_id,
              user_id: this.$route.query.user_id,
              unique_id: this.form_unique_id,
              account_id: Number(this.$route.query.account_id),
              token: this.$route.query.token,
            }
          );

          console.log(response);

          this.formIsSubmitted = true;

          setTimeout(() => {
            this.formIsSubmitted = false;
          }, 2000);

          setTimeout(() => {
            this.$router.go(-1);
          }, 3000);
        }
      } catch (error) {
        this.error = true;
        this.errorMessage = "Submitting form data " + error.message;
        console.log("Submitting form data " + error.message);

        setTimeout(() => {
          this.error = false;
          this.errorMessage = "";
          this.loading = false;
          this.formIsSubmitting = false;
        }, 3000);
      }
    },

    async getFormData() {
      try {
        this.loading = true;

        if (this.view_type === "logged_in") {
          const response = await API().get(
            `api/form/read_form_data?unique_id=${this.form_unique_id}`
          );
          if (response.status === 200) {
            this.formData = response.data;
            // console.log("form data: ", this.formData);
          }
          if (response.status === 204) {
            const new_form = await API().post(`api/form/create_form_data`, {
              form_data: this.formData,
            });
            this.formData = new_form.data;
            console.log("new form data: ", this.formData);
          }

          if (this.$route.query.response_id) {
            console.log(
              "This is an update view for the form response id number: ",
              this.$route.query.response_id
            );

            this.is_update_view = true;

            const res = await API().get(
              `api/form/read_form_response?id=${this.$route.query.response_id}`
            );

            console.log("found_form_data: ", res);

            const frm_rsp_sects = res.data.frm.frm_sects;

            this.formData.frm_sects.forEach((section) => {
              section.frm_qs.forEach((question) => {
                const rsp_section = frm_rsp_sects.find(
                  (sect) => sect.id === section.id
                );

                const rsp_question = rsp_section.frm_rsp_qs.find(
                  (q) => q.frm_q.id === question.id
                );

                question.response_id = rsp_question.id;
                question.answer = rsp_question.response;
                question.accounts = rsp_question.accounts;
                question.selected_choices = rsp_question.frm_opts.map(
                  (opt) => opt.id
                );

                if (question.frm_qs.length > 0) {
                  question.frm_qs.forEach((fq) => {
                    const rsp_fq = rsp_question.frm_rsp_qs.find(
                      (fq_rsp) => fq_rsp.frm_q.id === fq.id
                    );

                    fq.response_id = rsp_fq.id;
                    fq.answer = rsp_fq.response;
                  });
                }
              });
            });
          }

          this.loading = false;
        }

        if (this.view_type === "form_access_link") {
          console.log("form link token: ", this.form_access_token);
          const response = await API().get(
            `api/form-access-link/read_form_data?form_unique_id=${this.form_unique_id}&token=${this.$route.query.token}`
          );

          if (response.status === 200) {
            this.formData = response.data;
          }

          if (response.status === 204) {
            this.viewable = false;
          }

          console.log(this.formData);

          this.loading = false;
        }
      } catch (error) {
        this.error = true;
        this.errorMessage = "Submitting form data " + error.message;
        console.log("Submitting form data " + error.message);

        setTimeout(() => {
          this.error = false;
          this.errorMessage = "";
        }, 3000);
      }
    },
  },

  mounted() {
    this.getFormData();
  },
};
</script>

<style>
.bordered {
  border: 1px solid #444;
}
</style>
