<template>
  <v-container>
    <v-row v-if="loading">
      <v-col>
        <h3 class="text-center">
          <v-progress-circular indeterminate class="ml-3" color="primary" />
          Please be patient, this may take a while.
        </h3>
      </v-col>
    </v-row>

    <v-alert
      v-show="error"
      transition="fade-transition"
      color="error"
      type="error"
      style="z-index: 2; position: fixed; right: 15px; min-width: 300px"
    >
      {{ errorMessage }}
    </v-alert>

    <v-alert
      v-show="formIsSubmitted"
      transition="fade-transition"
      color="success"
      type="success"
      style="z-index: 2; position: fixed; right: 15px; min-width: 300px"
    >
      Thank you! The form has been submitted
    </v-alert>

    <v-row v-if="!loading" justify="center">
      <v-col xs="12" xl="6">
        <v-row>
          <v-col>
            <p class="text-h4">
              <v-icon class="text-h4"> mdi-account-details </v-icon>
              Personal
            </p>

            <p class="text-subtitle-1 mb-0">
              Lender Onboarding: Personal Investment Know Your Client (KYC) Form
            </p>

            <p class="text-subtitle-2 mb-0">
              Please fill out the following information to help us understand
              your investment preferences and risk tolerance.
            </p>
          </v-col>
        </v-row>

        <v-row v-if="formIsSubmitting">
          <v-col>
            <h3 class="text-center">
              <v-progress-circular indeterminate class="ml-3" color="primary" />
              Please be patient, this may take a while.
            </h3>
          </v-col>
        </v-row>

        <v-row v-if="!formIsSubmitting">
          <v-col>
            <v-stepper v-model="e1" vertical>
              <div v-for="(section, index) in formData.frm_sects" :key="index">
                <v-stepper-step
                  :complete="
                    section.title === 'Individual Sophisticated Investor'
                      ? section.frm_qs.some(
                          (question) => question.selected_choices.length > 0
                        )
                      : section.title === 'Accounts'
                      ? section.frm_qs.some((q) => q.accounts.length !== 0)
                      : section.is_valid
                  "
                  :step="index + 1"
                >
                  <span :class="e1 === index + 1 ? 'green--text' : ''">
                    {{ section.title }}
                    {{
                      section.title === "Individual Sophisticated Investor"
                        ? "( if applicable )"
                        : ""
                    }}
                  </span>
                </v-stepper-step>

                <v-stepper-content :step="index + 1">
                  <v-form v-model="section.is_valid">
                    <v-card class="mb-5">
                      <v-card-text>
                        <div
                          v-for="question in section.frm_qs"
                          :key="question.order"
                        >
                          <p class="text-body-1">{{ question.title }}</p>

                          <v-text-field
                            v-if="question.type === 'date'"
                            v-model="question.answer"
                            :rules="[rules.required]"
                            type="date"
                            outlined
                          />

                          <v-text-field
                            v-if="question.type === 'number'"
                            v-model="question.answer"
                            :rules="
                              question.title === 'Phone Number'
                                ? [rules.phone]
                                : [rules.required]
                            "
                            type="number"
                            outlined
                          />

                          <v-text-field
                            v-if="question.type === 'short text'"
                            v-model="question.answer"
                            :rules="
                              question.title === 'Email'
                                ? [rules.email]
                                : [rules.required]
                            "
                            outlined
                          />

                          <v-textarea
                            v-if="question.type === 'long text'"
                            v-model="question.answer"
                            :rules="[rules.required]"
                            auto-grow
                            outlined
                          />

                          <v-radio-group
                            v-if="question.type === 'single choice'"
                            v-model="question.answer"
                            :rules="[rules.required]"
                          >
                            <v-radio
                              v-for="option in question.frm_opts"
                              :key="option.order"
                              :label="option.choice"
                              :value="option.choice"
                            />
                          </v-radio-group>

                          <div v-if="question.type === 'multiple choice'">
                            <v-checkbox
                              v-model="question.selected_choices"
                              v-for="option in question.frm_opts"
                              :key="option.order"
                              :label="option.choice"
                              :value="option.id"
                              dense
                            />
                          </div>

                          <!-- follow up questions -->
                          <div
                            v-if="
                              section.title !== 'Accounts' &&
                              question.with_follow_up_question &&
                              question.answer === 'Yes'
                            "
                            class="bordered rounded-lg pa-5 mb-5"
                          >
                            <div
                              v-for="fq_1 in question.frm_qs"
                              :key="fq_1.order"
                            >
                              <p class="text-body-1">{{ fq_1.title }}</p>

                              <v-text-field
                                v-if="fq_1.type === 'number'"
                                v-model="fq_1.answer"
                                :rules="[rules.required]"
                                type="number"
                                outlined
                              />

                              <v-text-field
                                v-if="fq_1.type === 'short text'"
                                v-model="fq_1.answer"
                                :rules="[rules.required]"
                                outlined
                              />

                              <v-textarea
                                v-if="fq_1.type === 'long text'"
                                v-model="fq_1.answer"
                                :rules="[rules.required]"
                                auto-grow
                                outlined
                              />

                              <v-radio-group
                                v-if="fq_1.type === 'single choice'"
                                v-model="fq_1.answer"
                                :rules="[rules.required]"
                              >
                                <v-radio
                                  v-for="option in fq_1.frm_opts"
                                  :key="option.order"
                                  :label="option.choice"
                                  :value="option.choice"
                                />
                              </v-radio-group>
                            </div>
                          </div>

                          <!-- adding account -->
                          <div
                            v-if="
                              section.title === 'Accounts' &&
                              question.title ===
                                'Please provide the details of any accounts through which you will be making investments.'
                            "
                          >
                            <v-row v-if="question.accounts.length > 0">
                              <v-col>
                                <p>List of accounts added:</p>
                                <v-simple-table class="bordered">
                                  <template v-slot:default>
                                    <thead>
                                      <tr>
                                        <th>Name</th>
                                        <th>Category</th>
                                        <th>Type</th>
                                        <th>Account Number</th>
                                        <th>Renewal Responsibility</th>
                                        <th></th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      <tr
                                        v-for="(
                                          account, account_index
                                        ) in question.accounts"
                                        :key="account.id"
                                      >
                                        <td>{{ account.name }}</td>
                                        <td>{{ account.category }}</td>
                                        <td>{{ account.type }}</td>
                                        <td>{{ account.account_number }}</td>
                                        <td>
                                          {{ account.renewal_responsibility }}
                                        </td>
                                        <td>
                                          <v-btn
                                            v-if="!is_update_view"
                                            title="delete"
                                            icon
                                            color="red"
                                            @click="
                                              handle_remove_account_btn(
                                                account.id
                                              )
                                            "
                                          >
                                            <v-icon>
                                              mdi-trash-can-outline
                                            </v-icon>
                                          </v-btn>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                              </v-col>
                            </v-row>

                            <v-row v-if="!creating_account">
                              <v-col>
                                <v-btn @click="creating_account = true">
                                  Add Account
                                </v-btn>
                              </v-col>
                            </v-row>

                            <v-row
                              v-if="creating_account"
                              class="bordered rounded-lg mb-5"
                            >
                              <v-col>
                                <Add_Account
                                  viewed_as="kyc_add_personal_account"
                                  @handle_cancel="creating_account = false"
                                  @save_account="handle_save_account_name"
                                  :id="company_id"
                                />
                              </v-col>
                            </v-row>
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>

                    <v-btn
                      v-if="formData.frm_sects.length !== index + 1"
                      color="primary"
                      @click="e1 = index + 2"
                      :disabled="
                        section.title === 'Accounts'
                          ? section.frm_qs.some((q) => q.accounts.length === 0)
                          : !section.is_valid
                      "
                    >
                      Continue
                    </v-btn>

                    <v-btn
                      v-if="formData.frm_sects.length === index + 1"
                      color="primary"
                      :disabled="!section.is_valid || formIsSubmitting"
                      @click="handle_submit_btn"
                    >
                      Submit
                    </v-btn>

                    <v-btn v-if="index + 1 !== 1" @click="e1 = index" text>
                      back
                    </v-btn>
                  </v-form>
                </v-stepper-content>
              </div>
            </v-stepper>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn @click="$router.go(-1)" :disabled="formIsSubmitting">
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from "@/plugins/API";
import Add_Account from "@/views/admin/company/account/Add.vue";

export default {
  name: "Personal",

  props: [
    "form_unique_id",
    "company_id",
    "user_id",
    "view_type",
    "form_access_token",
  ],

  components: {
    Add_Account,
  },

  data: () => ({
    loading: false,
    error: false,
    is_update_view: false,
    formIsSubmitting: false,
    formIsSubmitted: false,
    errorMessage: "",
    e1: 1,

    rules: {
      required: (value) => !!value || "Required.",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
      phone: (value) =>
        (value && value.length == 10) ||
        "Phone number must be 10 digits no spaces or characters (format 7803334444)",
    },

    // creating accounts
    creating_account: false,

    formData: {
      title: "Personal",
      description: "",
      unique_id: "personal-kyc-2024-v1",

      frm_sects: [
        {
          order: 1,
          title: "Investment Knowledge",
          description: "",
          is_valid: false,

          frm_qs: [
            {
              order: 1,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title: "What is your level of mortgage investment knowledge?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                {
                  order: 1,
                  choice: "Novice",
                },
                {
                  order: 2,
                  choice: "Fair",
                },
                {
                  order: 3,
                  choice: "Good",
                },
                {
                  order: 4,
                  choice: "Sophisticated",
                },
              ],

              frm_qs: [],
            },

            {
              order: 2,
              is_a_follow_up_question: false,
              with_follow_up_question: true,
              answer: "",
              title: "Have you invested in mortgages before?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                {
                  order: 1,
                  choice: "Yes",
                },
                {
                  order: 2,
                  choice: "No",
                },
              ],

              frm_qs: [
                {
                  order: 1,
                  is_a_follow_up_question: true,
                  with_follow_up_question: false,
                  answer: "",
                  title: "How many years have you been investing in mortgages?",
                  description: "",
                  type: "number",
                  selected_choices: [],

                  frm_opts: [],

                  frm_qs: [],
                },

                {
                  order: 2,
                  is_a_follow_up_question: true,
                  with_follow_up_question: false,
                  answer: "",
                  title: "How many mortgages have you invested in?",
                  description: "",
                  type: "number",
                  selected_choices: [],

                  frm_opts: [],

                  frm_qs: [],
                },

                {
                  order: 3,
                  is_a_follow_up_question: true,
                  with_follow_up_question: false,
                  answer: "",
                  title:
                    "Approximately how much funds do you have invested in mortgages currently?",
                  description: "",
                  type: "number",
                  selected_choices: [],

                  frm_opts: [],

                  frm_qs: [],
                },

                {
                  order: 4,
                  is_a_follow_up_question: true,
                  with_follow_up_question: false,
                  answer: "",
                  title: "Have you ever had a mortgage go into foreclosure?",
                  description: "",
                  type: "single choice",
                  selected_choices: [],

                  frm_opts: [
                    {
                      order: 1,
                      choice: "Yes",
                    },
                    {
                      order: 2,
                      choice: "No",
                    },
                  ],

                  frm_qs: [],
                },
              ],
            },

            {
              order: 3,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title:
                "Are you aware of how fluctuations in housing market conditions can affect your security, including the possibility of losing some or all of your investment?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                {
                  order: 1,
                  choice: "Yes",
                },
                {
                  order: 2,
                  choice: "No",
                },
              ],

              frm_qs: [],
            },

            {
              order: 4,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title:
                "Do you have the ability to comfortably withstand a delay in the return of the funds invested in a mortgage given your current financial circumstances?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                {
                  order: 1,
                  choice: "Yes",
                },
                {
                  order: 2,
                  choice: "No",
                },
              ],

              frm_qs: [],
            },
          ],
        },

        {
          order: 2,
          title: "Individual Sophisticated Investor",
          is_valid: false,
          description: "",

          frm_qs: [
            {
              order: 1,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title:
                "Do you fit into any of the following categories? (Please check all that apply)",
              description: "",
              type: "multiple choice",
              selected_choices: [],

              frm_opts: [
                {
                  order: 1,
                  choice:
                    "A person or entity registered as an adviser or dealer under the Securities Act or another similar legislation in Canada.",
                },
                {
                  order: 2,
                  choice:
                    "An individual who, alone or together with a spouse, has net assets of at least $5 million and is able to provide written confirmation to the brokerage.",
                },
                {
                  order: 3,
                  choice:
                    "An individual who, alone or together with a spouse, beneficially owns financial assets that have an aggregate realizable value that, before taxes but net of any related liabilities, exceeds $1 million and is able to provide written confirmation to the brokerage.",
                },
                {
                  order: 4,
                  choice:
                    "An individual whose net income before taxes in each of the two most recent years exceeded $200,000 or whose combined income with a spouse exceeded $300,000 in those years, and who has a reasonable expectation of exceeding the same net income level in the current year. Written confirmation to the brokerage is available.",
                },
              ],

              frm_qs: [],
            },
          ],
        },

        {
          order: 3,
          title: "Financial Situation",
          is_valid: false,
          description: "",

          frm_qs: [
            {
              order: 1,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title:
                "What was your net personal income for the last two years?",
              description: "",
              type: "number",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 2,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title:
                "What was your combined net income with your spouse for the last two years (each year)?",
              description: "",
              type: "number",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 3,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title: "What is your approximate net worth?",
              description: "",
              type: "number",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 4,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title:
                "Do you understand the costs that may occur during defaults?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                {
                  order: 1,
                  choice: "Yes",
                },
                {
                  order: 2,
                  choice: "No",
                },
              ],

              frm_qs: [],
            },
          ],
        },

        {
          order: 4,
          title: "Liquidity",
          is_valid: false,
          description: "",

          frm_qs: [
            {
              order: 1,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title:
                "Do you need all your invested money back in the next year or two? Or are you investing in mortgages for the long term?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                {
                  order: 1,
                  choice: "Need funds back in the next year or two",
                },
                {
                  order: 2,
                  choice: "Long term mortgage investment",
                },
              ],

              frm_qs: [],
            },

            {
              order: 2,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title: "What is your liquidity requirement in years?",
              description: "",
              type: "number",
              selected_choices: [],

              frm_opts: [
                {
                  order: 1,
                  choice: "Need funds back in the next year or two",
                },
                {
                  order: 2,
                  choice: "Long term mortgage investment",
                },
              ],

              frm_qs: [],
            },

            {
              order: 3,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title:
                "What is your investment objective? (Please check all that apply)",
              description: "",
              type: "multiple choice",
              selected_choices: [],

              frm_opts: [
                { order: 1, choice: "Safety" },
                { order: 2, choice: "Income" },
                { order: 3, choice: "Balance" },
                { order: 4, choice: "Growth" },
                { order: 5, choice: "Aggressive" },
                { order: 6, choice: "Speculation preserve capital" },
                { order: 7, choice: "Generate income" },
                { order: 8, choice: "Generate income and growth" },
                {
                  order: 9,
                  choice:
                    "Generate maximum investment return regardless of higher risk",
                },
              ],

              frm_qs: [],
            },

            {
              order: 4,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title: "Are you borrowing money to lend via mortgages?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                { order: 1, choice: "Yes" },
                { order: 2, choice: "No" },
              ],

              frm_qs: [],
            },

            {
              order: 5,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title:
                "If you invested into a mortgage and took a 100% loss, would you still be able to survive financially?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                { order: 1, choice: "Yes" },
                { order: 2, choice: "No" },
              ],

              frm_qs: [],
            },
          ],
        },

        {
          order: 5,
          title: "Risk/LTV",
          is_valid: false,
          description: "",

          frm_qs: [
            {
              order: 1,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title: "What Provinces do you invest mortgages in?",
              description: "",
              type: "multiple choice",
              selected_choices: [],

              frm_opts: [
                { order: 1, choice: "Alberta" },
                { order: 2, choice: "British Columbia" },
                { order: 3, choice: "Ontario" },
              ],

              frm_qs: [],
            },

            {
              order: 2,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title: "Urban - House",
              description: "",
              type: "short text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 3,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title: "Urban - Condo",
              description: "",
              type: "short text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 4,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title: "Urban - Bare Land",
              description: "",
              type: "short text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 5,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title: "Rural - House",
              description: "",
              type: "short text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 6,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title: "Rural - Condo",
              description: "",
              type: "short text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 7,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title: "Rural - Bare Land",
              description: "",
              type: "short text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 8,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title: "What are your investment goals?",
              description: "",
              type: "long text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },
          ],
        },

        {
          order: 6,
          title: "Broker Declaration",
          is_valid: false,
          description: "",

          frm_qs: [
            {
              order: 1,
              is_a_follow_up_question: false,
              with_follow_up_question: true,
              answer: "",
              title:
                "Are you currently or formerly licensed as a mortgage broker in any Canadian province?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                { order: 1, choice: "Yes" },
                { order: 2, choice: "No" },
              ],

              frm_qs: [
                {
                  order: 1,
                  is_a_follow_up_question: true,
                  with_follow_up_question: false,
                  answer: "",
                  title: "Please indicate which province",
                  description: "",
                  type: "short text",
                  selected_choices: [],

                  frm_opts: [],

                  frm_qs: [],
                },
              ],
            },

            {
              id: 2,
              order: 2,
              is_a_follow_up_question: false,
              with_follow_up_question: true,
              answer: "",
              title:
                "Are you pooling together money from other people to invest in mortgages?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                { order: 1, choice: "Yes" },
                { order: 2, choice: "No" },
              ],

              frm_qs: [
                {
                  order: 1,
                  is_a_follow_up_question: true,
                  with_follow_up_question: false,
                  answer: "",
                  title: "Please describe",
                  description: "",
                  type: "short text",
                  selected_choices: [],

                  frm_opts: [],

                  frm_qs: [],
                },
              ],
            },
          ],
        },

        {
          order: 7,
          title: "Source of Funds",
          is_valid: false,
          description: "",

          frm_qs: [
            {
              order: 1,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title:
                "Please describe where you received your source of funds for investing in mortgages.",
              description: "",
              type: "long text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },
          ],
        },

        // {
        //   order: 8,
        //   title: "Accounts",
        //   is_valid: false,
        //   description: "",

        //   frm_qs: [
        //     {
        //       order: 1,
        //       is_a_follow_up_question: false,
        //       with_follow_up_question: true,
        //       answer: "",
        //       title:
        //         "Please provide the details of any accounts through which you will be making investments.",
        //       description: "",
        //       type: "single choice",
        //       selected_choices: [],

        //       frm_opts: [],

        //       frm_qs: [],

        //       accounts: [],
        //     },
        //   ],
        // },

        {
          order: 8,
          title: "Disclaimers",
          is_valid: false,
          description: "",

          frm_qs: [
            {
              order: 1,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title:
                "Do you understand that mortgages through EquityApproved.ca are not guaranteed or backed by any insurances to protect your investment?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                { order: 1, choice: "Yes" },
                { order: 2, choice: "No" },
              ],

              frm_qs: [],
            },

            {
              id: 2,
              order: 2,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title:
                "Do you understand that EquityApproved.ca focuses on approvals for borrowers based primarily on the available equity in the subject property? We forward information provided by borrowers but do not individually verify the information provided. It is up to the lender to verify the information provided by the borrower to their own satisfaction, and request written assistance if required from our brokerage.",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                { order: 1, choice: "Yes" },
                { order: 2, choice: "No" },
              ],

              frm_qs: [],
            },
          ],
        },

        {
          order: 11,
          title: "Personal/Emergency Contact Information",
          is_valid: false,
          description: "",

          frm_qs: [
            {
              order: 1,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title: "Address",
              description: "",
              type: "long text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 2,
              is_a_follow_up_question: false,
              answer: "",
              title: "Birthday",
              description: "",
              type: "date",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 3,
              is_a_follow_up_question: false,
              answer: "",
              title: "Marital Status",
              description: "",
              type: "short text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 4,
              is_a_follow_up_question: false,
              answer: "",
              title: "Number of dependents",
              description: "",
              type: "number",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 5,
              is_a_follow_up_question: false,
              answer: "",
              title: "Occupation",
              description: "",
              type: "short text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 6,
              is_a_follow_up_question: false,
              answer: "",
              title:
                "Emergency Contact Information (In case of death or illness to handle mortgage affairs)",
              description: "",
              type: null,
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 7,
              is_a_follow_up_question: false,
              answer: "",
              title: "Name",
              description: "",
              type: "short text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 8,
              is_a_follow_up_question: false,
              answer: "",
              title: "Phone Number",
              description: "",
              type: "number",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 9,
              is_a_follow_up_question: false,
              answer: "",
              title: "Email",
              description: "",
              type: "short text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 10,
              is_a_follow_up_question: false,
              answer: "",
              title: "Relationship",
              description: "",
              type: "short text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },
          ],
        },
      ],
    },

    createdAccounts: [],
  }),

  methods: {
    async handle_remove_account_btn(account_id) {
      if (window.confirm("Are you sure you want to delete this account?")) {
        try {
          let res = null;

          if (this.view_type === "logged_in") {
            res = await API().post(`api/form/delete_account`, {
              account_id: account_id,
            });
          }

          if (this.view_type === "form_access_link") {
            res = await API().post(`api/form-access-link/delete_account`, {
              account_id: account_id,
              token: this.$route.query.token,
            });
          }

          if (res.status === 201) {
            console.log("account was deleted ", res.data);
            this.will_remove_account_to_form_data(res.data);
          }

          if (res.status === 204) console.log("account was not found.");
        } catch (error) {
          console.log(error.response.data.message);
          this.error = true;
          this.errorMessage = error.response.data.message;
        }
      } else {
        console.log("account was not deleted");
      }
    },

    will_remove_account_to_form_data(data) {
      const section = this.formData.frm_sects.find(
        (section) => section.title === "Accounts"
      );

      const question = section.frm_qs.find((question) => question.order === 1);

      question.accounts = question.accounts.filter(
        (account) => account.id !== data.id
      );
    },

    async handle_save_account_name(data) {
      try {
        let res = null;
        console.log("data: ", data);

        if (this.view_type === "logged_in") {
          res = await API().post(`api/form/create_account`, {
            company_id: this.company_id,
            account_data: data,
          });
        }

        if (this.view_type === "form_access_link") {
          res = await API().post(`api/form-access-link/create_account`, {
            company_id: this.company_id,
            account_data: data,
            token: this.$route.query.token,
          });
        }

        if (res.status === 201) {
          console.log("account has been added", res);

          this.will_add_account_to_form_data(res.data);
        }

        if (res.status === 200) console.log("account is not added", res);

        this.creating_account = false;
      } catch (error) {
        console.log(error.response.data.message);
        this.error = true;
        this.errorMessage = error.response.data.message;
      }
    },

    will_add_account_to_form_data(data) {
      const section = this.formData.frm_sects.find(
        (section) => section.title === "Accounts"
      );

      const question = section.frm_qs.find((question) => question.order === 1);

      question.accounts.push(data);
    },

    async handle_submit_btn() {
      try {
        this.formIsSubmitting = true;
        console.log(
          "This formData should be sending to the backend: ",
          this.formData
        );

        if (this.view_type === "logged_in") {
          if (this.is_update_view) {
            const response = await API().patch(`api/form/update_kyc_response`, {
              form_data: this.formData,
              unique_id: this.form_unique_id,
            });

            console.log(response);
          } else {
            const response = await API().post(`api/form/create_kyc_response`, {
              form_data: this.formData,
              company_id: this.company_id,
              user_id: this.user_id,
              unique_id: this.form_unique_id,
              account_id: this.createdAccounts.map((account) => account.id),
            });

            console.log(response);
          }

          this.formIsSubmitted = true;

          setTimeout(() => {
            this.formIsSubmitted = false;
          }, 2000);

          setTimeout(() => {
            this.$router.go(-1);
          }, 3000);
        }

        if (this.view_type === "form_access_link") {
          const response = await API().post(
            `api/form-access-link/create_kyc_response`,
            {
              form_data: this.formData,
              company_id: this.company_id,
              user_id: this.$route.query.user_id,
              unique_id: this.form_unique_id,
              account_id: this.createdAccounts.map((account) => account.id),
              token: this.$route.query.token,
            }
          );

          console.log(response);

          this.formIsSubmitted = true;

          setTimeout(() => {
            this.formIsSubmitted = false;
          }, 2000);

          setTimeout(() => {
            this.$router.go(-1);
          }, 3000);
        }
      } catch (error) {
        this.error = true;
        this.errorMessage = "Submitting form data " + error.message;
        console.log("Submitting form data " + error.message);

        setTimeout(() => {
          this.error = false;
          this.errorMessage = "";
          this.loading = false;
          this.formIsSubmitting = false;
        }, 3000);
      }
    },

    async getFormData() {
      try {
        this.loading = true;
        if (this.view_type === "logged_in") {
          const response = await API().get(
            `api/form/read_form_data?unique_id=${this.form_unique_id}`
          );
          if (response.status === 200) {
            this.formData = response.data;
            console.log("form data: ", this.formData);
          }
          if (response.status === 204) {
            const new_form = await API().post(`api/form/create_form_data`, {
              form_data: this.formData,
            });
            this.formData = new_form.data;
            console.log("new form data: ", this.formData);
          }
          if (this.$route.query.response_id) {
            console.log(
              "This is an update view for the form id number: ",
              this.$route.query.response_id
            );
            this.is_update_view = true;
            const res = await API().get(
              `api/form/read_form_response?id=${this.$route.query.response_id}`
            );
            console.log("found_form_data: ", res);
            const frm_rsp_sects = res.data.frm.frm_sects;
            this.formData.frm_sects.forEach((section) => {
              section.frm_qs.forEach((question) => {
                const rsp_section = frm_rsp_sects.find(
                  (sect) => sect.id === section.id
                );
                const rsp_question = rsp_section.frm_rsp_qs.find(
                  (q) => q.frm_q.id === question.id
                );
                question.response_id = rsp_question.id;
                question.answer = rsp_question.response;
                question.accounts = rsp_question.accounts;
                question.selected_choices = rsp_question.frm_opts.map(
                  (opt) => opt.id
                );
                if (question.frm_qs.length > 0) {
                  question.frm_qs.forEach((fq) => {
                    const rsp_fq = rsp_question.frm_rsp_qs.find(
                      (fq_rsp) => fq_rsp.frm_q.id === fq.id
                    );
                    fq.response_id = rsp_fq.id;
                    fq.answer = rsp_fq.response;
                  });
                }
              });
            });
          }
          this.loading = false;
        }
        if (this.view_type === "form_access_link") {
          console.log("form link token: ", this.form_access_token);
          const response = await API().get(
            `api/form-access-link/read_form_data?form_unique_id=${this.form_unique_id}&token=${this.$route.query.token}`
          );
          if (response.status === 200) {
            this.formData = response.data;
          }
          if (response.status === 204) {
            this.viewable = false;
          }
          console.log(this.formData);
          this.loading = false;
        }
      } catch (error) {
        this.error = true;
        this.errorMessage = "Submitting form data " + error.message;
        console.log("Submitting form data " + error.message);

        setTimeout(() => {
          this.error = false;
          this.errorMessage = "";
        }, 3000);
      }
    },
  },

  mounted() {
    this.getFormData();
  },
};
</script>

<style>
.bordered {
  border: 1px solid #444;
}
</style>
