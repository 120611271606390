<template>
  <v-container>
    <!-- notifications -->
    <v-alert
      v-model="error"
      transition="fade-transition"
      type="error"
      style="z-index: 2; position: fixed; right: 15px"
      max-width="500px"
    >
      <v-icon class="float-right" @click="error = false">mdi-close</v-icon>
      <div class="mr-10 text-subtitle-1">
        {{ errorMessage }}
      </div>
    </v-alert>

    <v-alert
      v-show="formIsSubmitted"
      transition="fade-transition"
      color="success"
      type="success"
      style="z-index: 2; position: fixed; right: 15px; min-width: 300px"
    >
      Thank you! The form has been submitted
    </v-alert>

    <v-row v-if="loading">
      <v-col>
        <h3 class="text-center">
          <v-progress-circular indeterminate class="ml-3" color="primary" />
          Please be patient, this may take a while.
        </h3>
      </v-col>
    </v-row>

    <v-row v-if="!loading" justify="center">
      <v-col xs="12" xl="6">
        <v-row>
          <v-col>
            <p class="text-h4">
              <v-icon class="text-h4"> mdi-account-details </v-icon>
              Initial
            </p>

            <p class="text-subtitle-1 mb-0">
              Initial Investor Onboarding: Know Your Client (KYC) Form
            </p>

            <p class="text-subtitle-2 mb-0">
              Please fill out the following information to help us understand
              your investment preferences and risk tolerance.
            </p>
          </v-col>
        </v-row>

        <v-row v-if="formIsSubmitting">
          <v-col>
            <h3 class="text-center">
              <v-progress-circular indeterminate class="ml-3" color="primary" />
              Please be patient, this may take a while.
            </h3>
          </v-col>
        </v-row>

        <v-row v-if="!formIsSubmitting">
          <v-col>
            <v-stepper v-model="e1" vertical>
              <div v-for="(section, index) in formData.frm_sects" :key="index">
                <v-stepper-step
                  :complete="
                    section.title === 'Individual Sophisticated Investor'
                      ? section.frm_qs.some(
                          (question) => question.selected_choices.length > 0
                        )
                      : section.title === 'Accounts'
                      ? section.frm_qs.some((q) => q.frm_qs.length !== 0)
                      : section.is_valid
                  "
                  :step="index + 1"
                >
                  <span :class="e1 === index + 1 ? 'green--text' : ''">
                    {{ section.title }}
                    {{
                      section.title === "Individual Sophisticated Investor"
                        ? "( if applicable )"
                        : ""
                    }}
                  </span>
                </v-stepper-step>

                <v-stepper-content :step="index + 1">
                  <v-form v-model="section.is_valid">
                    <v-card class="mb-5">
                      <v-card-text>
                        <div
                          v-for="question in section.frm_qs"
                          :key="question.order"
                        >
                          <p class="text-body-1">{{ question.title }}</p>

                          <v-text-field
                            v-if="question.type === 'date'"
                            v-model="question.answer"
                            :rules="[rules.required]"
                            type="date"
                            outlined
                          />

                          <v-text-field
                            v-if="question.type === 'number'"
                            v-model="question.answer"
                            :rules="
                              question.title === 'Phone Number'
                                ? [rules.phone]
                                : [rules.required]
                            "
                            type="number"
                            outlined
                          />

                          <v-text-field
                            v-if="question.type === 'short text'"
                            v-model="question.answer"
                            :rules="
                              question.title === 'Email'
                                ? [rules.email]
                                : [rules.required]
                            "
                            outlined
                          />

                          <v-textarea
                            v-if="question.type === 'long text'"
                            v-model="question.answer"
                            :rules="[rules.required]"
                            auto-grow
                            outlined
                          />

                          <v-radio-group
                            v-if="question.type === 'single choice'"
                            v-model="question.answer"
                            :rules="[rules.required]"
                          >
                            <v-radio
                              v-for="option in question.frm_opts"
                              :key="option.order"
                              :label="option.choice"
                              :value="option.choice"
                            />
                          </v-radio-group>

                          <div v-if="question.type === 'multiple choice'">
                            <v-checkbox
                              v-model="question.selected_choices"
                              v-for="option in question.frm_opts"
                              :key="option.order"
                              :label="option.choice"
                              :value="option.choice"
                              dense
                            />
                          </div>

                          <!-- follow up questions -->
                          <div
                            v-if="
                              question.title !==
                                'Are you investing in mortgages with funds from a corporation you own?' &&
                              question.title !==
                                'Are you investing in mortgages on behalf of a corporation that is primarily in the business of lending money via mortgages (e.g., Mortgage Investment Company, Mortgage Brokerage)?' &&
                              question.with_follow_up_question &&
                              question.answer === 'Yes'
                            "
                            class="bordered rounded-lg pa-5 mb-5"
                          >
                            <div
                              v-for="fq_1 in question.frm_qs"
                              :key="fq_1.order"
                            >
                              <p class="text-body-1">{{ fq_1.title }}</p>

                              <v-text-field
                                v-if="fq_1.type === 'number'"
                                v-model="fq_1.answer"
                                :rules="[rules.required]"
                                type="number"
                                outlined
                              />

                              <v-text-field
                                v-if="fq_1.type === 'short text'"
                                v-model="fq_1.answer"
                                :rules="[rules.required]"
                                outlined
                              />

                              <v-textarea
                                v-if="fq_1.type === 'long text'"
                                v-model="fq_1.answer"
                                :rules="[rules.required]"
                                auto-grow
                                outlined
                              />

                              <v-radio-group
                                v-if="fq_1.type === 'single choice'"
                                v-model="fq_1.answer"
                                :rules="[rules.required]"
                                @change="
                                  fq_1.title ===
                                    'Do you invest in mortgages using a personal cash account?' &&
                                  fq_1.answer === 'Yes'
                                    ? handle_save_new_account('Cash', 'Private')
                                    : fq_1.title ===
                                        'Do you invest in mortgages using a personal cash account?' &&
                                      fq_1.answer === 'No'
                                    ? new_accounts.splice(
                                        new_accounts.findIndex(
                                          (a) => a.type === 'Cash'
                                        ),
                                        1
                                      )
                                    : ''
                                "
                              >
                                <v-radio
                                  v-for="option in fq_1.frm_opts"
                                  :key="option.order"
                                  :label="option.choice"
                                  :value="option.choice"
                                />
                              </v-radio-group>

                              <!-- RRSP account -->
                              <div
                                v-if="
                                  fq_1.answer === 'Yes' &&
                                  fq_1.title ===
                                    'Do you invest in Mortgages with an RRSP?'
                                "
                              >
                                <!-- RRSP list of all accounts -->
                                <v-row>
                                  <v-col>
                                    <p>List of accounts added:</p>

                                    <v-simple-table class="bordered">
                                      <template v-slot:default>
                                        <thead>
                                          <tr>
                                            <th>Name</th>
                                            <th>Account Number</th>
                                            <th>Renewal Responsibility</th>
                                            <th></th>
                                          </tr>
                                        </thead>

                                        <tbody>
                                          <tr
                                            v-if="
                                              RRSP_accounts.length === 0 &&
                                              New_RRSP_accounts.length === 0
                                            "
                                          >
                                            <td
                                              colspan="4"
                                              class="text-center red--text"
                                            >
                                              Required at least one account
                                            </td>
                                          </tr>

                                          <!-- existing -->
                                          <tr
                                            v-for="(
                                              account, account_index
                                            ) in RRSP_accounts"
                                            :key="account.id"
                                          >
                                            <td>{{ account.name }}</td>
                                            <td>
                                              {{ account.account_number }}
                                            </td>
                                            <td>
                                              {{
                                                account.renewal_responsibility
                                              }}
                                            </td>
                                            <td></td>
                                          </tr>

                                          <!-- new accounts -->
                                          <tr
                                            v-for="(
                                              new_account, account_index
                                            ) in New_RRSP_accounts"
                                            :key="new_account.order"
                                          >
                                            <td>{{ new_account.name }}</td>
                                            <td>
                                              {{ new_account.account_number }}
                                            </td>
                                            <td>
                                              {{
                                                new_account.renewal_responsibility
                                              }}
                                            </td>
                                            <td class="text-right">
                                              <v-btn
                                                title="Remove"
                                                @click="
                                                  handle_remove_new_account(
                                                    new_account.order
                                                  )
                                                "
                                                icon
                                              >
                                                <v-icon color="red">
                                                  mdi-close</v-icon
                                                >
                                              </v-btn>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </template>
                                    </v-simple-table>
                                  </v-col>
                                </v-row>

                                <!-- RRSP add account button -->
                                <v-row
                                  v-if="!creating_rrsp_account"
                                  class="mb-5"
                                >
                                  <v-col>
                                    <v-btn
                                      title="Add RRSP Account"
                                      @click="creating_rrsp_account = true"
                                    >
                                      + Add Account
                                    </v-btn>
                                  </v-col>
                                </v-row>

                                <!-- RRSP account form -->
                                <v-row>
                                  <v-col v-if="creating_rrsp_account">
                                    <v-card class="bordered rounded-lg mb-5">
                                      <v-card-title>
                                        Creating Account
                                      </v-card-title>

                                      <v-card-text>
                                        <v-form v-model="is_rrsp_account_valid">
                                          <v-row>
                                            <v-col>
                                              <v-select
                                                v-model="RRSP_name"
                                                label="Select Trust Company"
                                                :items="[
                                                  'Olympia',
                                                  'Canadian Western',
                                                ]"
                                                :rules="[rules.required]"
                                                hide-details="auto"
                                              />
                                            </v-col>
                                            <v-col>
                                              <v-text-field
                                                v-model="RRSP_account_number"
                                                label="Account Number"
                                                hide-details="auto"
                                              />
                                            </v-col>
                                          </v-row>

                                          <v-row>
                                            <v-col>
                                              <v-select
                                                v-model="
                                                  RRSP_renewal_responsibility
                                                "
                                                label="Renewal Responsibility"
                                                :items="[
                                                  'EquityApproved.ca',
                                                  'Lender',
                                                ]"
                                                hide-details="auto"
                                              />
                                            </v-col>

                                            <v-col></v-col>
                                          </v-row>
                                        </v-form>
                                      </v-card-text>

                                      <v-card-actions>
                                        <v-row>
                                          <v-col>
                                            <v-btn
                                              class="mr-2"
                                              color="primary"
                                              :disabled="!is_rrsp_account_valid"
                                              @click="
                                                handle_save_new_account(
                                                  'RRSP',
                                                  'Private'
                                                )
                                              "
                                              text
                                            >
                                              save
                                            </v-btn>

                                            <v-btn
                                              @click="
                                                creating_rrsp_account = false
                                              "
                                              text
                                            >
                                              cancel
                                            </v-btn>
                                          </v-col>
                                        </v-row>
                                      </v-card-actions>
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </div>

                              <!-- RRIF account -->
                              <div
                                v-if="
                                  fq_1.answer === 'Yes' &&
                                  fq_1.title ===
                                    'Do you invest in mortgages with a RRIF?'
                                "
                              >
                                <!-- RRIF list of all accounts -->
                                <v-row>
                                  <v-col>
                                    <p>List of accounts added:</p>

                                    <v-simple-table class="bordered">
                                      <template v-slot:default>
                                        <thead>
                                          <tr>
                                            <th>Name</th>
                                            <th>Account Number</th>
                                            <th>Renewal Responsibility</th>
                                            <th></th>
                                          </tr>
                                        </thead>

                                        <tbody>
                                          <tr
                                            v-if="
                                              RRIF_accounts.length === 0 &&
                                              New_RRIF_accounts.length === 0
                                            "
                                          >
                                            <td
                                              colspan="4"
                                              class="text-center red--text"
                                            >
                                              Required at least one account
                                            </td>
                                          </tr>

                                          <!-- existing -->
                                          <tr
                                            v-for="(
                                              account, account_index
                                            ) in RRIF_accounts"
                                            :key="account.id"
                                          >
                                            <td>{{ account.name }}</td>
                                            <td>
                                              {{ account.account_number }}
                                            </td>
                                            <td>
                                              {{
                                                account.renewal_responsibility
                                              }}
                                            </td>
                                            <td></td>
                                          </tr>

                                          <!-- new accounts -->
                                          <tr
                                            v-for="(
                                              new_account, account_index
                                            ) in New_RRIF_accounts"
                                            :key="new_account.order"
                                          >
                                            <td>{{ new_account.name }}</td>
                                            <td>
                                              {{ new_account.account_number }}
                                            </td>
                                            <td>
                                              {{
                                                new_account.renewal_responsibility
                                              }}
                                            </td>
                                            <td class="text-right">
                                              <v-btn
                                                title="Remove"
                                                @click="
                                                  handle_remove_new_account(
                                                    new_account.order
                                                  )
                                                "
                                                icon
                                              >
                                                <v-icon color="red">
                                                  mdi-close
                                                </v-icon>
                                              </v-btn>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </template>
                                    </v-simple-table>
                                  </v-col>
                                </v-row>

                                <!-- RRIF add account button -->
                                <v-row
                                  v-if="!creating_rrif_account"
                                  class="mb-5"
                                >
                                  <v-col>
                                    <v-btn
                                      title="Add RRIF Account"
                                      @click="creating_rrif_account = true"
                                    >
                                      + Add Account
                                    </v-btn>
                                  </v-col>
                                </v-row>

                                <!-- RRIF account form -->
                                <v-row>
                                  <v-col v-if="creating_rrif_account">
                                    <v-card class="bordered rounded-lg mb-5">
                                      <v-card-title>
                                        Creating Account
                                      </v-card-title>

                                      <v-card-text>
                                        <v-form v-model="is_rrif_account_valid">
                                          <v-row>
                                            <v-col>
                                              <v-select
                                                v-model="RRIF_name"
                                                label="Select Trust Company"
                                                :items="[
                                                  'Olympia',
                                                  'Canadian Western',
                                                ]"
                                                :rules="[rules.required]"
                                                hide-details="auto"
                                              />
                                            </v-col>
                                            <v-col>
                                              <v-text-field
                                                v-model="RRIF_account_number"
                                                label="Account Number"
                                                hide-details="auto"
                                              />
                                            </v-col>
                                          </v-row>

                                          <v-row>
                                            <v-col>
                                              <v-select
                                                v-model="
                                                  RRIF_renewal_responsibility
                                                "
                                                label="Renewal Responsibility"
                                                :items="[
                                                  'EquityApproved.ca',
                                                  'Lender',
                                                ]"
                                                hide-details="auto"
                                              />
                                            </v-col>

                                            <v-col></v-col>
                                          </v-row>
                                        </v-form>
                                      </v-card-text>

                                      <v-card-actions>
                                        <v-row>
                                          <v-col>
                                            <v-btn
                                              class="mr-2"
                                              color="primary"
                                              :disabled="!is_rrif_account_valid"
                                              @click="
                                                handle_save_new_account(
                                                  'RRIF',
                                                  'Private'
                                                )
                                              "
                                              text
                                            >
                                              save
                                            </v-btn>

                                            <v-btn
                                              @click="
                                                creating_rrif_account = false
                                              "
                                              text
                                            >
                                              cancel
                                            </v-btn>
                                          </v-col>
                                        </v-row>
                                      </v-card-actions>
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </div>

                              <!-- TFSA account -->
                              <div
                                v-if="
                                  fq_1.answer === 'Yes' &&
                                  fq_1.title ===
                                    'Do you invest in mortgages with a TFSA?'
                                "
                              >
                                <!-- TFSA list of all accounts -->
                                <v-row>
                                  <v-col>
                                    <p>List of accounts added:</p>

                                    <v-simple-table class="bordered">
                                      <template v-slot:default>
                                        <thead>
                                          <tr>
                                            <th>Name</th>
                                            <th>Account Number</th>
                                            <th>Renewal Responsibility</th>
                                            <th></th>
                                          </tr>
                                        </thead>

                                        <tbody>
                                          <tr
                                            v-if="
                                              TFSA_accounts.length === 0 &&
                                              New_TFSA_accounts.length === 0
                                            "
                                          >
                                            <td
                                              colspan="4"
                                              class="text-center red--text"
                                            >
                                              Required at least one account
                                            </td>
                                          </tr>

                                          <!-- existing -->
                                          <tr
                                            v-for="(
                                              account, account_index
                                            ) in TFSA_accounts"
                                            :key="account.id"
                                          >
                                            <td>{{ account.name }}</td>
                                            <td>
                                              {{ account.account_number }}
                                            </td>
                                            <td>
                                              {{
                                                account.renewal_responsibility
                                              }}
                                            </td>
                                            <td></td>
                                          </tr>

                                          <!-- new accounts -->
                                          <tr
                                            v-for="(
                                              new_account, account_index
                                            ) in New_TFSA_accounts"
                                            :key="new_account.order"
                                          >
                                            <td>{{ new_account.name }}</td>
                                            <td>
                                              {{ new_account.account_number }}
                                            </td>
                                            <td>
                                              {{
                                                new_account.renewal_responsibility
                                              }}
                                            </td>
                                            <td class="text-right">
                                              <v-btn
                                                title="Remove"
                                                @click="
                                                  handle_remove_new_account(
                                                    new_account.order
                                                  )
                                                "
                                                icon
                                              >
                                                <v-icon color="red">
                                                  mdi-close
                                                </v-icon>
                                              </v-btn>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </template>
                                    </v-simple-table>
                                  </v-col>
                                </v-row>

                                <!-- TFSA add account button -->
                                <v-row
                                  v-if="!creating_tfsa_account"
                                  class="mb-5"
                                >
                                  <v-col>
                                    <v-btn
                                      title="Add TFSA Account"
                                      @click="creating_tfsa_account = true"
                                    >
                                      + Add Account
                                    </v-btn>
                                  </v-col>
                                </v-row>

                                <!-- TFSA account form -->
                                <v-row>
                                  <v-col v-if="creating_tfsa_account">
                                    <v-card class="bordered rounded-lg mb-5">
                                      <v-card-title>
                                        Creating Account
                                      </v-card-title>

                                      <v-card-text>
                                        <v-form v-model="is_tfsa_account_valid">
                                          <v-row>
                                            <v-col>
                                              <v-select
                                                v-model="TFSA_name"
                                                label="Select Trust Company"
                                                :items="[
                                                  'Olympia',
                                                  'Canadian Western',
                                                ]"
                                                :rules="[rules.required]"
                                                hide-details="auto"
                                              />
                                            </v-col>
                                            <v-col>
                                              <v-text-field
                                                v-model="TFSA_account_number"
                                                label="Account Number"
                                                hide-details="auto"
                                              />
                                            </v-col>
                                          </v-row>

                                          <v-row>
                                            <v-col>
                                              <v-select
                                                v-model="
                                                  TFSA_renewal_responsibility
                                                "
                                                label="Renewal Responsibility"
                                                :items="[
                                                  'EquityApproved.ca',
                                                  'Lender',
                                                ]"
                                                hide-details="auto"
                                              />
                                            </v-col>

                                            <v-col></v-col>
                                          </v-row>
                                        </v-form>
                                      </v-card-text>

                                      <v-card-actions>
                                        <v-row>
                                          <v-col>
                                            <v-btn
                                              class="mr-2"
                                              color="primary"
                                              :disabled="!is_tfsa_account_valid"
                                              @click="
                                                handle_save_new_account(
                                                  'TFSA',
                                                  'Private'
                                                )
                                              "
                                              text
                                            >
                                              save
                                            </v-btn>

                                            <v-btn
                                              @click="
                                                creating_tfsa_account = false
                                              "
                                              text
                                            >
                                              cancel
                                            </v-btn>
                                          </v-col>
                                        </v-row>
                                      </v-card-actions>
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </div>
                            </div>
                          </div>

                          <!-- Mortgage company account -->
                          <div
                            v-if="
                              question.answer === 'Yes' &&
                              question.title ===
                                'Are you investing in mortgages on behalf of a corporation that is primarily in the business of lending money via mortgages (e.g., Mortgage Investment Company, Mortgage Brokerage)?'
                            "
                          >
                            <!-- Mortgage company list of all accounts -->
                            <v-row>
                              <v-col>
                                <p>List of accounts added:</p>

                                <v-simple-table class="bordered">
                                  <template v-slot:default>
                                    <thead>
                                      <tr>
                                        <th>Name</th>
                                        <th>Category</th>
                                        <th>Renewal Responsibility</th>
                                        <th></th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      <tr
                                        v-if="
                                          mortgage_company_accounts.length ===
                                            0 &&
                                          New_mortgage_company_accounts.length ===
                                            0
                                        "
                                      >
                                        <td
                                          colspan="4"
                                          class="text-center red--text"
                                        >
                                          Required at least one account
                                        </td>
                                      </tr>

                                      <!-- existing -->
                                      <tr
                                        v-for="(
                                          account, account_index
                                        ) in mortgage_company_accounts"
                                        :key="account.id"
                                      >
                                        <td>{{ account.name }}</td>
                                        <td>{{ account.category }}</td>
                                        <td>
                                          {{ account.renewal_responsibility }}
                                        </td>
                                        <td></td>
                                      </tr>

                                      <!-- new accounts -->
                                      <tr
                                        v-for="(
                                          new_account, account_index
                                        ) in New_mortgage_company_accounts"
                                        :key="new_account.order"
                                      >
                                        <td>{{ new_account.name }}</td>
                                        <td>{{ new_account.category }}</td>
                                        <td>
                                          {{
                                            new_account.renewal_responsibility
                                          }}
                                        </td>
                                        <td class="text-right">
                                          <v-btn
                                            title="Remove"
                                            @click="
                                              handle_remove_new_account(
                                                new_account.order
                                              )
                                            "
                                            icon
                                          >
                                            <v-icon color="red">
                                              mdi-close
                                            </v-icon>
                                          </v-btn>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                              </v-col>
                            </v-row>

                            <!-- Mortgage company add account button -->
                            <v-row
                              v-if="!creating_mortgage_company_account"
                              class="mb-5"
                            >
                              <v-col>
                                <v-btn
                                  title="Add Mortgage Company Account"
                                  @click="
                                    creating_mortgage_company_account = true
                                  "
                                >
                                  + Add Account
                                </v-btn>
                              </v-col>
                            </v-row>

                            <!-- Mortgage company account form -->
                            <v-row>
                              <v-col v-if="creating_mortgage_company_account">
                                <v-card class="bordered rounded-lg mb-5">
                                  <v-card-title>
                                    Creating Account
                                  </v-card-title>

                                  <v-card-text>
                                    <v-form
                                      v-model="
                                        is_mortgage_company_account_valid
                                      "
                                    >
                                      <v-row>
                                        <v-col>
                                          <v-select
                                            v-model="mortgage_company_category"
                                            label="Select Category"
                                            :items="[
                                              'Bank',
                                              'Credit Union',
                                              'Insurance Company',
                                              'Mortgage Finance Company (MFC)',
                                              'Mortgage Investment Entity (MIE)',
                                              'Brokerage (Self Funding)',
                                              'Trust Company',
                                            ]"
                                            :rules="[rules.required]"
                                            hide-details="auto"
                                          />
                                        </v-col>

                                        <v-col>
                                          <v-text-field
                                            v-model="mortgage_company_name"
                                            label="Name"
                                            hide-details="auto"
                                          />
                                        </v-col>
                                      </v-row>

                                      <v-row>
                                        <v-col>
                                          <v-select
                                            v-model="
                                              mortgage_company_renewal_responsibility
                                            "
                                            label="Renewal Responsibility"
                                            :items="[
                                              'EquityApproved.ca',
                                              'Lender',
                                            ]"
                                            hide-details="auto"
                                          />
                                        </v-col>

                                        <v-col> </v-col>
                                      </v-row>
                                    </v-form>

                                    <!-- address section -->
                                    <v-row>
                                      <v-col>
                                        <v-expansion-panels>
                                          <v-expansion-panel class="bordered">
                                            <v-expansion-panel-header>
                                              <div>Address</div>
                                            </v-expansion-panel-header>

                                            <v-expansion-panel-content>
                                              <v-row class="mb-5">
                                                <v-col
                                                  cols="12"
                                                  class="pb-0 mb-n2"
                                                >
                                                  <div
                                                    v-if="
                                                      mortgage_company_addresses.length >
                                                      0
                                                    "
                                                    class="mb-5"
                                                  >
                                                    <p
                                                      class="grey--text text-subtitle-2 mb-2"
                                                    >
                                                      List of added address:
                                                    </p>

                                                    <v-data-table
                                                      :headers="header_address"
                                                      item-key="order"
                                                      :items="
                                                        mortgage_company_addresses
                                                      "
                                                      :items-per-page="5"
                                                      @click:row="
                                                        handle_updating_address(
                                                          $event,
                                                          'mortgage_company'
                                                        )
                                                      "
                                                      v-model="
                                                        selected_mortgage_company_addresses
                                                      "
                                                      show-select
                                                    >
                                                      <template
                                                        v-slot:[`item.address`]="{
                                                          item,
                                                        }"
                                                      >
                                                        {{ item.street_number }}
                                                        {{ item.street_name }}
                                                        {{ item.street_type }}
                                                        {{
                                                          item.street_direction
                                                        }}
                                                        {{ item.unit }}
                                                        {{ item.city }}
                                                        {{ item.province }}
                                                        {{ item.postal_code }}
                                                      </template>
                                                    </v-data-table>

                                                    <v-btn
                                                      v-if="
                                                        selected_mortgage_company_addresses.length >
                                                        0
                                                      "
                                                      @click="
                                                        handle_delete_address(
                                                          'mortgage_company'
                                                        )
                                                      "
                                                      color="error"
                                                      class="mt-2"
                                                    >
                                                      delete
                                                    </v-btn>
                                                  </div>

                                                  <div>
                                                    <v-btn
                                                      v-if="
                                                        !adding_mortgage_company_address &&
                                                        !updating_mortgage_company_address
                                                      "
                                                      @click="
                                                        adding_mortgage_company_address = true
                                                      "
                                                    >
                                                      Add Address
                                                    </v-btn>
                                                  </div>

                                                  <Address
                                                    v-if="
                                                      adding_mortgage_company_address ||
                                                      updating_mortgage_company_address
                                                    "
                                                    :adding_address="
                                                      adding_mortgage_company_address
                                                    "
                                                    :updating_address="
                                                      updating_mortgage_company_address
                                                    "
                                                    :address_data="
                                                      new_mortgage_company_address
                                                    "
                                                    :main_address_data="
                                                      mortgage_company_addresses
                                                    "
                                                    :no_time_at_residence="true"
                                                    :rule="{
                                                      required: [
                                                        (v) =>
                                                          !!v || 'Required.',
                                                      ],
                                                    }"
                                                    @save_add="
                                                      handle_save_address(
                                                        $event,
                                                        'mortgage_company'
                                                      )
                                                    "
                                                    @save_update="
                                                      handle_save_update_address(
                                                        $event,
                                                        'mortgage_company'
                                                      )
                                                    "
                                                    @close="
                                                      adding_mortgage_company_address = false;
                                                      updating_mortgage_company_address = false;
                                                    "
                                                  />
                                                </v-col>
                                              </v-row>
                                            </v-expansion-panel-content>
                                          </v-expansion-panel>
                                        </v-expansion-panels>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>

                                  <v-card-actions>
                                    <v-row>
                                      <v-col>
                                        <v-btn
                                          class="mr-2"
                                          color="primary"
                                          :disabled="
                                            !is_mortgage_company_account_valid
                                          "
                                          @click="
                                            handle_save_new_account(
                                              'Corporation',
                                              mortgage_company_category
                                            )
                                          "
                                          text
                                        >
                                          save
                                        </v-btn>

                                        <v-btn
                                          @click="
                                            creating_mortgage_company_account = false
                                          "
                                          text
                                        >
                                          cancel
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                  </v-card-actions>
                                </v-card>
                              </v-col>
                            </v-row>
                          </div>

                          <!-- Corporation account -->
                          <div
                            v-if="
                              question.answer === 'Yes' &&
                              question.title ===
                                'Are you investing in mortgages with funds from a corporation you own?'
                            "
                          >
                            <!-- Corporation list of all accounts -->
                            <v-row>
                              <v-col>
                                <p>List of accounts added:</p>

                                <v-simple-table class="bordered">
                                  <template v-slot:default>
                                    <thead>
                                      <tr>
                                        <th>Name</th>
                                        <th>Renewal Responsibility</th>
                                        <th></th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      <tr
                                        v-if="
                                          private_corporation_accounts.length ===
                                            0 &&
                                          New_private_corporation_accounts.length ===
                                            0
                                        "
                                      >
                                        <td
                                          colspan="4"
                                          class="text-center red--text"
                                        >
                                          Required at least one account
                                        </td>
                                      </tr>

                                      <!-- existing -->
                                      <tr
                                        v-for="(
                                          account, account_index
                                        ) in private_corporation_accounts"
                                        :key="account.id"
                                      >
                                        <td>{{ account.name }}</td>
                                        <td>
                                          {{ account.renewal_responsibility }}
                                        </td>
                                        <td></td>
                                      </tr>

                                      <!-- new accounts -->
                                      <tr
                                        v-for="(
                                          new_account, account_index
                                        ) in New_private_corporation_accounts"
                                        :key="new_account.order"
                                      >
                                        <td>{{ new_account.name }}</td>
                                        <td>
                                          {{
                                            new_account.renewal_responsibility
                                          }}
                                        </td>
                                        <td class="text-right">
                                          <v-btn
                                            title="Remove"
                                            @click="
                                              handle_remove_new_account(
                                                new_account.order
                                              )
                                            "
                                            icon
                                          >
                                            <v-icon color="red">
                                              mdi-close
                                            </v-icon>
                                          </v-btn>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                              </v-col>
                            </v-row>

                            <!-- Corporation add account button -->
                            <v-row
                              v-if="!creating_corporation_account"
                              class="mb-5"
                            >
                              <v-col>
                                <v-btn
                                  title="Add Corporation Account"
                                  @click="creating_corporation_account = true"
                                >
                                  + Add Account
                                </v-btn>
                              </v-col>
                            </v-row>

                            <!-- Corporation account form -->
                            <v-row>
                              <v-col v-if="creating_corporation_account">
                                <v-card class="bordered rounded-lg mb-5">
                                  <v-card-title>
                                    Creating Account
                                  </v-card-title>

                                  <v-card-text>
                                    <v-form
                                      v-model="is_corporation_account_valid"
                                    >
                                      <v-row>
                                        <v-col>
                                          <v-text-field
                                            v-model="corporation_name"
                                            label="Name"
                                            hide-details="auto"
                                          />
                                        </v-col>

                                        <v-col>
                                          <v-select
                                            v-model="
                                              corporation_renewal_responsibility
                                            "
                                            label="Renewal Responsibility"
                                            :items="[
                                              'EquityApproved.ca',
                                              'Lender',
                                            ]"
                                            hide-details="auto"
                                          />
                                        </v-col>
                                      </v-row>
                                    </v-form>

                                    <!-- address section -->
                                    <v-row>
                                      <v-col>
                                        <v-expansion-panels>
                                          <v-expansion-panel class="bordered">
                                            <v-expansion-panel-header>
                                              <div>Address</div>
                                            </v-expansion-panel-header>

                                            <v-expansion-panel-content>
                                              <v-row class="mb-5">
                                                <v-col
                                                  cols="12"
                                                  class="pb-0 mb-n2"
                                                >
                                                  <div
                                                    v-if="
                                                      corporation_addresses.length >
                                                      0
                                                    "
                                                    class="mb-5"
                                                  >
                                                    <p
                                                      class="grey--text text-subtitle-2 mb-2"
                                                    >
                                                      List of added address:
                                                    </p>

                                                    <v-data-table
                                                      :headers="header_address"
                                                      item-key="order"
                                                      :items="
                                                        corporation_addresses
                                                      "
                                                      :items-per-page="5"
                                                      @click:row="
                                                        handle_updating_address(
                                                          $event,
                                                          'corporation'
                                                        )
                                                      "
                                                      v-model="
                                                        selected_corporation_addresses
                                                      "
                                                      show-select
                                                    >
                                                      <template
                                                        v-slot:[`item.address`]="{
                                                          item,
                                                        }"
                                                      >
                                                        {{ item.street_number }}
                                                        {{ item.street_name }}
                                                        {{ item.street_type }}
                                                        {{
                                                          item.street_direction
                                                        }}
                                                        {{ item.unit }}
                                                        {{ item.city }}
                                                        {{ item.province }}
                                                        {{ item.postal_code }}
                                                      </template>
                                                    </v-data-table>

                                                    <v-btn
                                                      v-if="
                                                        selected_corporation_addresses.length >
                                                        0
                                                      "
                                                      @click="
                                                        handle_delete_address(
                                                          'corporation'
                                                        )
                                                      "
                                                      color="error"
                                                      class="mt-2"
                                                    >
                                                      delete
                                                    </v-btn>
                                                  </div>

                                                  <div>
                                                    <v-btn
                                                      v-if="
                                                        !adding_corporation_address &&
                                                        !updating_corporation_address
                                                      "
                                                      @click="
                                                        adding_corporation_address = true
                                                      "
                                                    >
                                                      Add Address
                                                    </v-btn>
                                                  </div>

                                                  <Address
                                                    v-if="
                                                      adding_corporation_address ||
                                                      updating_corporation_address
                                                    "
                                                    :adding_address="
                                                      adding_corporation_address
                                                    "
                                                    :updating_address="
                                                      updating_corporation_address
                                                    "
                                                    :address_data="
                                                      new_corporation_address
                                                    "
                                                    :main_address_data="
                                                      corporation_addresses
                                                    "
                                                    :no_time_at_residence="true"
                                                    :rule="{
                                                      required: [
                                                        (v) =>
                                                          !!v || 'Required.',
                                                      ],
                                                    }"
                                                    @save_add="
                                                      handle_save_address(
                                                        $event,
                                                        'corporation'
                                                      )
                                                    "
                                                    @save_update="
                                                      handle_save_update_address(
                                                        $event,
                                                        'corporation'
                                                      )
                                                    "
                                                    @close="
                                                      adding_corporation_address = false;
                                                      updating_corporation_address = false;
                                                    "
                                                  />
                                                </v-col>
                                              </v-row>
                                            </v-expansion-panel-content>
                                          </v-expansion-panel>
                                        </v-expansion-panels>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>

                                  <v-card-actions>
                                    <v-row>
                                      <v-col>
                                        <v-btn
                                          class="mr-2"
                                          color="primary"
                                          :disabled="
                                            !is_corporation_account_valid
                                          "
                                          @click="
                                            handle_save_new_account(
                                              'Corporation',
                                              'Private'
                                            )
                                          "
                                          text
                                        >
                                          save
                                        </v-btn>

                                        <v-btn
                                          @click="
                                            creating_corporation_account = false
                                          "
                                          text
                                        >
                                          cancel
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                  </v-card-actions>
                                </v-card>
                              </v-col>
                            </v-row>
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>

                    <v-btn
                      v-if="formData.frm_sects.length !== index + 1"
                      color="primary"
                      @click="e1 = index + 2"
                      :disabled="
                        section.title === 'Investment Information'
                          ? section.frm_qs.some((q) => {
                              if (
                                q.title ===
                                'Are you investing in mortgages on behalf of a corporation that is primarily in the business of lending money via mortgages (e.g., Mortgage Investment Company, Mortgage Brokerage)?'
                              ) {
                                return (
                                  q.answer === 'Yes' &&
                                  mortgage_company_accounts.length === 0 &&
                                  New_mortgage_company_accounts.length === 0
                                );
                              }

                              if (
                                q.title ===
                                'Are you investing in mortgages with funds from a corporation you own?'
                              ) {
                                return (
                                  q.answer === 'Yes' &&
                                  private_corporation_accounts.length === 0 &&
                                  New_private_corporation_accounts.length === 0
                                );
                              }

                              return q.frm_qs.some((fq_1) => {
                                if (
                                  fq_1.title ===
                                  'Do you invest in Mortgages with an RRSP?'
                                ) {
                                  return (
                                    fq_1.answer === 'Yes' &&
                                    RRSP_accounts.length === 0 &&
                                    New_RRSP_accounts.length === 0
                                  );
                                }

                                if (
                                  fq_1.title ===
                                  'Do you invest in mortgages with a RRIF?'
                                ) {
                                  return (
                                    fq_1.answer === 'Yes' &&
                                    RRIF_accounts.length === 0 &&
                                    New_RRIF_accounts.length === 0
                                  );
                                }

                                if (
                                  fq_1.title ===
                                  'Do you invest in mortgages with a TFSA?'
                                ) {
                                  return (
                                    fq_1.answer === 'Yes' &&
                                    TFSA_accounts.length === 0 &&
                                    New_TFSA_accounts.length === 0
                                  );
                                }
                              });
                            })
                          : !section.is_valid
                      "
                    >
                      Continue
                    </v-btn>

                    <v-btn
                      v-if="formData.frm_sects.length === index + 1"
                      color="primary"
                      :disabled="!section.is_valid || formIsSubmitting"
                      @click="handle_submit_btn"
                    >
                      Submit
                    </v-btn>

                    <v-btn v-if="index + 1 !== 1" @click="e1 = index" text>
                      back
                    </v-btn>
                  </v-form>
                </v-stepper-content>
              </div>
            </v-stepper>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn @click="$router.go(-1)" :disabled="formIsSubmitting">
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from "@/plugins/API";
import Address from "@/components/customer/AddAddress.vue";

export default {
  name: "Initial",

  props: [
    "form_unique_id",
    "company_id",
    "user_id",
    "view_type",
    "form_access_token",
  ],

  components: {
    Address,
  },

  data: () => ({
    loading: false,
    error: false,
    is_update_view: false,
    formIsSubmitting: false,
    formIsSubmitted: false,
    errorMessage: "",

    // creating accounts
    new_accounts: [],

    RRSP_name: null,
    RRSP_renewal_responsibility: null,
    RRSP_account_number: null,

    RRIF_name: null,
    RRIF_renewal_responsibility: null,
    RRIF_account_number: null,

    TFSA_name: null,
    TFSA_renewal_responsibility: null,
    TFSA_account_number: null,

    mortgage_company_name: null,
    mortgage_company_category: null,
    mortgage_company_account_number: null,
    mortgage_company_renewal_responsibility: null,
    mortgage_company_addresses: [],
    new_mortgage_company_address: {},
    adding_mortgage_company_address: false,
    updating_mortgage_company_address: false,
    selected_mortgage_company_addresses: [],

    corporation_name: null,
    corporation_account_number: null,
    corporation_renewal_responsibility: null,
    corporation_addresses: [],
    new_corporation_address: {},
    adding_corporation_address: false,
    updating_corporation_address: false,
    selected_corporation_addresses: [],

    creating_rrsp_account: false,
    is_rrsp_account_valid: false,

    creating_rrif_account: false,
    is_rrif_account_valid: false,

    creating_tfsa_account: false,
    is_tfsa_account_valid: false,

    creating_mortgage_company_account: false,
    is_mortgage_company_account_valid: false,

    creating_corporation_account: false,
    is_corporation_account_valid: false,

    e1: 1,

    rules: {
      required: (value) => !!value || "Required.",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
      phone: (value) =>
        (value && value.length == 10) ||
        "Phone number must be 10 digits no spaces or characters (format 7803334444)",
    },

    // field options
    renewal_responsibility_options: ["EquityApproved.ca", "Lender"],

    // table headers

    header_address: [
      { text: "Address", value: "address" },
      { text: "Present", value: "present" },
    ],

    formData: {
      title: "Initial",
      description: "",
      unique_id: "initial-kyc-2024-v1",

      frm_sects: [
        {
          order: 1,
          title: "Personal Information",
          description: "",
          is_valid: false,

          frm_qs: [
            {
              order: 1,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title: "Name",
              description: "",
              type: "short text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 2,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title: "Email",
              description: "",
              type: "short text",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },

            {
              order: 3,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title: "Phone Number",
              description: "",
              type: "number",
              selected_choices: [],

              frm_opts: [],

              frm_qs: [],
            },
          ],
        },

        {
          order: 2,
          title: "Investment Information",
          is_valid: false,
          description: "",

          frm_qs: [
            {
              order: 1,
              is_a_follow_up_question: false,
              with_follow_up_question: true,
              answer: "",
              title:
                "Are you investing in mortgages using personal funds (e.g., RRSP, TFSA, Cash)?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                { order: 1, choice: "Yes" },
                { order: 2, choice: "No" },
              ],

              frm_qs: [
                {
                  order: 1,
                  is_a_follow_up_question: true,
                  with_follow_up_question: false,
                  answer: "",
                  title: "Do you invest in Mortgages with an RRSP?",
                  description: "",
                  type: "single choice",
                  selected_choices: [],
                  frm_opts: [
                    { order: 1, choice: "Yes" },
                    { order: 2, choice: "No" },
                  ],
                  frm_qs: [],

                  accounts: [],
                },

                {
                  order: 2,
                  is_a_follow_up_question: true,
                  with_follow_up_question: false,
                  answer: "",
                  title: "Do you invest in mortgages with a RRIF?",
                  description: "",
                  type: "single choice",
                  selected_choices: [],
                  frm_opts: [
                    { order: 1, choice: "Yes" },
                    { order: 2, choice: "No" },
                  ],
                  frm_qs: [],

                  accounts: [],
                },

                {
                  order: 3,
                  is_a_follow_up_question: true,
                  with_follow_up_question: false,
                  answer: "",
                  title: "Do you invest in mortgages with a TFSA?",
                  description: "",
                  type: "single choice",
                  selected_choices: [],
                  frm_opts: [
                    { order: 1, choice: "Yes" },
                    { order: 2, choice: "No" },
                  ],
                  frm_qs: [],

                  accounts: [],
                },

                {
                  order: 4,
                  is_a_follow_up_question: true,
                  with_follow_up_question: false,
                  answer: "",
                  title:
                    "Do you invest in mortgages using a personal cash account?",
                  description: "",
                  type: "single choice",
                  selected_choices: [],
                  frm_opts: [
                    { order: 1, choice: "Yes" },
                    { order: 2, choice: "No" },
                  ],
                  frm_qs: [],
                },
              ],
            },

            {
              order: 2,
              is_a_follow_up_question: false,
              with_follow_up_question: true,
              answer: "",
              title:
                "Are you investing in mortgages on behalf of a corporation that is primarily in the business of lending money via mortgages (e.g., Mortgage Investment Company, Mortgage Brokerage)?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                { order: 1, choice: "Yes" },
                { order: 2, choice: "No" },
              ],

              frm_qs: [],

              accounts: [],
            },

            {
              order: 3,
              is_a_follow_up_question: false,
              with_follow_up_question: true,
              answer: "",
              title:
                "Are you investing in mortgages with funds from a corporation you own?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                { order: 1, choice: "Yes" },
                { order: 2, choice: "No" },
              ],

              frm_qs: [],

              accounts: [],
            },
          ],
        },

        {
          order: 3,
          title: "Risk Assessment",
          is_valid: false,
          description: "",

          frm_qs: [
            {
              order: 1,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title:
                "Are you aware of how fluctuations in housing market conditions can affect your security with potential loss of some or all your investment?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                {
                  order: 1,
                  choice: "Yes",
                },
                {
                  order: 2,
                  choice: "No",
                },
              ],

              frm_qs: [],
            },

            {
              order: 2,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title:
                "Do you have the ability to comfortably withstand a delay in the return of the principal/capital invested in the non-qualified syndicated mortgage given your current financial circumstances?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                {
                  order: 1,
                  choice: "Yes",
                },
                {
                  order: 2,
                  choice: "No",
                },
              ],

              frm_qs: [],
            },

            {
              order: 3,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title:
                "Do you understand the costs incurred during defaults and mortgage enforcement?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                {
                  order: 1,
                  choice: "Yes",
                },
                {
                  order: 2,
                  choice: "No",
                },
              ],

              frm_qs: [],
            },

            {
              order: 4,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title:
                "If you invested into a mortgage and took a 100% loss, would you still be able to survive financially?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                {
                  order: 1,
                  choice: "Yes",
                },
                {
                  order: 2,
                  choice: "No",
                },
              ],

              frm_qs: [],
            },

            {
              order: 5,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title:
                "Are you pooling together money from other people to invest in mortgages?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                {
                  order: 1,
                  choice: "Yes",
                },
                {
                  order: 2,
                  choice: "No",
                },
              ],

              frm_qs: [],
            },

            {
              order: 6,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title:
                "Do you understand that mortgages through EquityApproved.ca are not guaranteed or backed by any insurances to protect your investment?",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                {
                  order: 1,
                  choice: "Yes",
                },
                {
                  order: 2,
                  choice: "No",
                },
              ],

              frm_qs: [],
            },

            {
              order: 7,
              is_a_follow_up_question: false,
              with_follow_up_question: false,
              answer: "",
              title:
                "Do you understand that EquityApproved.ca focuses on approvals for borrowers based primarily on the available equity in the subject property? We forward information provided by borrowers but do not individually verify the information provided. It is up to the lender to verify the information provided by the borrower to their own satisfaction, and request written assistance if required from our brokerage.",
              description: "",
              type: "single choice",
              selected_choices: [],

              frm_opts: [
                {
                  order: 1,
                  choice: "Yes",
                },
                {
                  order: 2,
                  choice: "No",
                },
              ],

              frm_qs: [],
            },
          ],
        },
      ],
    },

    companyData: null,
  }),

  methods: {
    handle_save_new_account(type, category) {
      let new_order = 0;

      if (this.new_accounts.length === 0) {
        // add 1 if length equal to zero
        new_order = new_order + 1;
      } else {
        // get the heighest order then add 1
        const highest_order = Math.max.apply(
          Math,
          this.new_accounts.map((item) => item.order)
        );
        new_order = highest_order + 1;
      }

      let temp_account = {
        account_number: null,
        addresses: [],
        category: null,
        name: null,
        order: new_order,
        renewal_responsibility: null,
        type: null,
      };

      switch (type) {
        case "RRSP": {
          temp_account = {
            account_number: this.RRSP_account_number,
            addresses: [],
            category: category,
            name: this.RRSP_name,
            order: new_order,
            renewal_responsibility: this.RRSP_renewal_responsibility,
            type: type,
          };

          // add default addresses if the name either Olympia or Canadian Western
          switch (this.RRSP_name) {
            case "Olympia": {
              const new_address = {
                line1: null,
                street_number: "P.O. BOX 2581",
                street_name: "STN Central",
                street_direction: null,
                line2: null,
                street_type: null,
                unit: null,
                postal_code: "T2P 1C8",
                city: "Calgary",
                province: "Alberta",
                present: true,
              };

              temp_account.addresses.push(new_address);
              break;
            }

            case "Canadian Western": {
              const new_address = {
                line1: null,
                street_number: "300 750",
                street_name: "Cambie Street",
                street_direction: null,
                line2: null,
                street_type: null,
                unit: null,
                postal_code: "V6B 0A2",
                city: "Vancouver",
                province: "British Columbia",
                present: true,
              };

              temp_account.addresses.push(new_address);
              break;
            }
          }

          // clear the form
          this.RRSP_name = null;
          this.RRSP_renewal_responsibility = null;
          this.RRSP_account_number = null;

          this.creating_rrsp_account = false;
          break;
        }

        case "RRIF": {
          temp_account = {
            account_number: this.RRIF_account_number,
            addresses: [],
            category: category,
            name: this.RRIF_name,
            order: new_order,
            renewal_responsibility: this.RRIF_renewal_responsibility,
            type: type,
          };

          // add default addresses if the name either Olympia or Canadian Western
          switch (this.RRIF_name) {
            case "Olympia": {
              const new_address = {
                line1: null,
                street_number: "P.O. BOX 2581",
                street_name: "STN Central",
                street_direction: null,
                line2: null,
                street_type: null,
                unit: null,
                postal_code: "T2P 1C8",
                city: "Calgary",
                province: "Alberta",
                present: true,
              };

              temp_account.addresses.push(new_address);
              break;
            }

            case "Canadian Western": {
              const new_address = {
                line1: null,
                street_number: "300 750",
                street_name: "Cambie Street",
                street_direction: null,
                line2: null,
                street_type: null,
                unit: null,
                postal_code: "V6B 0A2",
                city: "Vancouver",
                province: "British Columbia",
                present: true,
              };

              temp_account.addresses.push(new_address);
              break;
            }
          }

          // clear the form
          this.RRIF_name = null;
          this.RRIF_renewal_responsibility = null;
          this.RRIF_account_number = null;

          this.creating_rrif_account = false;
          break;
        }

        case "TFSA": {
          temp_account = {
            account_number: this.TFSA_account_number,
            addresses: [],
            category: category,
            name: this.TFSA_name,
            order: new_order,
            renewal_responsibility: this.TFSA_renewal_responsibility,
            type: type,
          };

          // add default addresses if the name either Olympia or Canadian Western
          switch (this.TFSA_name) {
            case "Olympia": {
              const new_address = {
                line1: null,
                street_number: "P.O. BOX 2581",
                street_name: "STN Central",
                street_direction: null,
                line2: null,
                street_type: null,
                unit: null,
                postal_code: "T2P 1C8",
                city: "Calgary",
                province: "Alberta",
                present: true,
              };

              temp_account.addresses.push(new_address);
              break;
            }

            case "Canadian Western": {
              const new_address = {
                line1: null,
                street_number: "300 750",
                street_name: "Cambie Street",
                street_direction: null,
                line2: null,
                street_type: null,
                unit: null,
                postal_code: "V6B 0A2",
                city: "Vancouver",
                province: "British Columbia",
                present: true,
              };

              temp_account.addresses.push(new_address);
              break;
            }
          }

          // clear the form
          this.TFSA_name = null;
          this.TFSA_renewal_responsibility = null;
          this.TFSA_account_number = null;

          this.creating_tfsa_account = false;
          break;
        }

        case "Cash": {
          temp_account = {
            account_number: null,
            addresses: [],
            category: category,
            name: this.companyData.name,
            order: new_order,
            renewal_responsibility: null,
            type: type,
          };
          break;
        }

        case "Corporation": {
          if (category === "Private") {
            temp_account = {
              account_number: this.corporation_account_number,
              addresses: this.corporation_addresses,
              category: category,
              name: this.corporation_name,
              order: new_order,
              renewal_responsibility: this.corporation_renewal_responsibility,
              type: type,
            };

            // clear the form
            this.corporation_name = null;
            this.corporation_account_number = null;
            this.corporation_renewal_responsibility = null;
            this.corporation_addresses = [];

            this.creating_corporation_account = false;
          } else {
            temp_account = {
              account_number: this.mortgage_company_account_number,
              addresses: this.mortgage_company_addresses,
              category: category,
              name: this.mortgage_company_name,
              order: new_order,
              renewal_responsibility:
                this.mortgage_company_renewal_responsibility,
              type: type,
            };

            // clear the form
            this.mortgage_company_name = null;
            this.mortgage_company_account_number = null;
            this.mortgage_company_renewal_responsibility = null;
            this.mortgage_company_addresses = [];

            this.creating_mortgage_company_account = false;
          }
          break;
        }
      }

      this.new_accounts.push(temp_account);
      console.log("new_accounts: ", this.new_accounts);
    },

    handle_remove_new_account(account_order) {
      if (window.confirm("Are you sure you want to remove this account?")) {
        this.new_accounts = this.new_accounts.filter(
          (account) => account.order !== account_order
        );
      } else {
        console.log("cancelled");
      }
    },

    handle_save_address(data, type) {
      if (type === "corporation") {
        let new_order = null;

        if (this.corporation_addresses.length === 0) {
          new_order = 1;
        } else {
          const highest_order = Math.max.apply(
            Math,
            this.corporation_addresses.map((item) => item.order)
          );
          new_order = highest_order + 1;
        }

        data.order = new_order;

        this.corporation_addresses.push(data);
        this.adding_corporation_address = false;
      }

      if (type === "mortgage_company") {
        let new_order = null;

        if (this.mortgage_company_addresses.length === 0) {
          new_order = 1;
        } else {
          const highest_order = Math.max.apply(
            Math,
            this.mortgage_company_addresses.map((item) => item.order)
          );
          new_order = highest_order + 1;
        }

        data.order = new_order;

        this.mortgage_company_addresses.push(data);
        this.adding_mortgage_company_address = false;
      }
    },

    handle_save_update_address(data, type) {
      if (type === "corporation") {
        this.corporation_addresses = this.corporation_addresses.map(
          (address) => {
            if (address.order === data.order) {
              return data;
            }
            return address;
          }
        );

        this.updating_corporation_address = false;
      }

      if (type === "mortgage_company") {
        this.mortgage_company_addresses = this.mortgage_company_addresses.map(
          (address) => {
            if (address.order === data.order) {
              return data;
            }
            return address;
          }
        );

        this.updating_mortgage_company_address = false;
      }
    },

    handle_delete_address(type) {
      if (window.confirm("Are you sure you want to delete this address?")) {
        if (type === "corporation") {
          this.corporation_addresses = this.corporation_addresses.filter(
            (address) => {
              return !this.selected_corporation_addresses.includes(address);
            }
          );

          this.selected_corporation_addresses = [];
        }

        if (type === "mortgage_company") {
          this.mortgage_company_addresses =
            this.mortgage_company_addresses.filter((address) => {
              return !this.selected_mortgage_company_addresses.includes(
                address
              );
            });

          this.selected_mortgage_company_addresses = [];
        }
      } else {
        console.log("cancelled");
      }
    },

    handle_updating_address(event, type) {
      if (type === "corporation") {
        this.new_corporation_address = event;
        this.updating_corporation_address = true;
      }

      if (type === "mortgage_company") {
        this.new_mortgage_company_address = event;
        this.updating_mortgage_company_address = true;
      }
    },

    will_check_investment_info_quetions() {
      const RRSP_RRIF_TFSA_Cash_exist = this.companyData.accounts.some(
        (account) =>
          account.category === "Private" &&
          (account.type === "RRSP" ||
            account.type === "RRIF" ||
            account.type === "TFSA" ||
            account.type === "Cash")
      );
      // console.log("RRSP_RRIF_TFSA_Cash_exist: ", RRSP_RRIF_TFSA_Cash_exist);
      this.formData.frm_sects[1].frm_qs[0].answer = RRSP_RRIF_TFSA_Cash_exist
        ? "Yes"
        : null;

      const RRSP_exist = this.companyData.accounts.some(
        (account) => account.category === "Private" && account.type === "RRSP"
      );
      // console.log("RRSP_exist: ", RRSP_exist);
      this.formData.frm_sects[1].frm_qs[0].frm_qs[0].answer = RRSP_exist
        ? "Yes"
        : null;

      const RRIF_exist = this.companyData.accounts.some(
        (account) => account.category === "Private" && account.type === "RRIF"
      );
      // console.log("RRIF_exist: ", RRIF_exist);
      this.formData.frm_sects[1].frm_qs[0].frm_qs[1].answer = RRIF_exist
        ? "Yes"
        : null;

      const TFSA_exist = this.companyData.accounts.some(
        (account) => account.category === "Private" && account.type === "TFSA"
      );
      // console.log("TFSA_exist: ", TFSA_exist);
      this.formData.frm_sects[1].frm_qs[0].frm_qs[2].answer = TFSA_exist
        ? "Yes"
        : null;

      const Cash_exist = this.companyData.accounts.some(
        (account) => account.category === "Private" && account.type === "Cash"
      );
      // console.log("Cash_exist: ", Cash_exist);
      this.formData.frm_sects[1].frm_qs[0].frm_qs[3].answer = Cash_exist
        ? "Yes"
        : null;

      const Corporation_exist = this.companyData.accounts.some(
        (account) =>
          account.category === "Private" && account.type === "Corporation"
      );
      // console.log("Corporation_exist: ", Corporation_exist);
      this.formData.frm_sects[1].frm_qs[2].answer = Corporation_exist
        ? "Yes"
        : null;

      const Mortgage_Company_exist = this.companyData.accounts.some(
        (account) => account.category !== "Private"
      );
      // console.log("Mortgage_Company_exist: ", Mortgage_Company_exist);
      this.formData.frm_sects[1].frm_qs[1].answer = Mortgage_Company_exist
        ? "Yes"
        : null;
    },

    async handle_submit_btn() {
      try {
        this.formIsSubmitting = true;
        console.log(
          "This formData should be sending to the backend: ",
          this.formData
        );

        console.log("new_accounts: ", this.new_accounts);

        let response = null;

        if (this.view_type === "logged_in") {
          this.new_accounts.forEach(async (account) => {
            const account_res = await API().post(`api/form/create_account`, {
              company_id: Number(this.company_id),
              account_data: account,
            });

            console.log("account_res: ", account_res);
          });

          if (this.is_update_view) {
            response = await API().patch(`api/form/update_kyc_response`, {
              form_data: this.formData,
              unique_id: this.form_unique_id,
            });
          } else {
            response = await API().post(`api/form/create_kyc_response`, {
              form_data: this.formData,
              company_id: this.company_id,
              user_id: this.user_id,
              unique_id: this.form_unique_id,
            });
          }

          console.log(response);

          this.formIsSubmitted = true;

          setTimeout(() => {
            this.$router.push({
              path: `/dashboard/admin/company/${this.company_id}/kyc/homepage`,
            });
          }, 3000);
        }

        if (this.view_type === "form_access_link") {
          response = await API().post(
            `api/form-access-link/create_kyc_response`,
            {
              form_data: this.formData,
              company_id: this.company_id,
              user_id: this.$route.query.user_id,
              unique_id: this.form_unique_id,
              token: this.$route.query.token,
            }
          );

          console.log(response);

          this.formIsSubmitted = true;

          setTimeout(() => {
            this.$router.go(-1);
          }, 3000);
        }
      } catch (error) {
        console.log(error.response.data.message);
        this.error = true;
        this.errorMessage = error.response.data.message;
        this.loading = false;
        this.formIsSubmitting = false;
      }
    },

    async getFormData() {
      try {
        this.loading = true;

        if (this.view_type === "logged_in") {
          const company_data_res = await API().get(
            `/api/internal-admin/company/${this.company_id}`
          );
          // console.log("companyData: ", company_data_res);

          this.companyData = company_data_res.data;

          const response = await API().get(
            `api/form/read_form_data?unique_id=${this.form_unique_id}`
          );
          if (response.status === 200) {
            this.formData = response.data;
            // console.log("Form Data: ", this.formData);
          }

          if (response.status === 204) {
            const new_form = await API().post(`api/form/create_form_data`, {
              form_data: this.formData,
            });
            this.formData = new_form.data;
            console.log("new form data: ", this.formData);
          }

          if (this.$route.query.response_id) {
            this.is_update_view = true;

            const res = await API().get(
              `api/form/read_form_response?id=${this.$route.query.response_id}`
            );

            console.log("found_form_data: ", res);

            const frm_rsp_sects = res.data.frm.frm_sects;

            this.formData.frm_sects.forEach((section) => {
              section.frm_qs.forEach((question) => {
                const rsp_section = frm_rsp_sects.find(
                  (sect) => sect.id === section.id
                );

                const rsp_question = rsp_section.frm_rsp_qs.find(
                  (q) => q.frm_q.id === question.id
                );

                question.response_id = rsp_question.id;
                question.answer = rsp_question.response;
                question.accounts = rsp_question.accounts;

                if (question.frm_qs.length > 0) {
                  question.frm_qs.forEach((fq) => {
                    const rsp_fq = rsp_question.frm_rsp_qs.find(
                      (fq_rsp) => fq_rsp.frm_q.id === fq.id
                    );

                    fq.response_id = rsp_fq.id;
                    fq.answer = rsp_fq.response;
                    fq.accounts = rsp_fq.accounts;
                  });
                }
              });
            });
          }

          this.will_check_investment_info_quetions();

          this.loading = false;
        }

        if (this.view_type === "form_access_link") {
          console.log("form link token: ", this.form_access_token);
          const response = await API().get(
            `api/form-access-link/read_form_data?form_unique_id=${this.form_unique_id}&token=${this.$route.query.token}`
          );

          if (response.status === 200) {
            this.formData = response.data;
          }

          if (response.status === 204) {
            this.viewable = false;
          }

          console.log(this.formData);

          this.loading = false;
        }
      } catch (error) {
        console.log(error.response.data.message);
        this.error = true;
        this.errorMessage = error.response.data.message;
        this.loading = true;
      }
    },
  },

  computed: {
    // existing accounts
    RRSP_accounts() {
      return this.companyData.accounts.filter(
        (account) => account.category === "Private" && account.type === "RRSP"
      );
    },

    TFSA_accounts() {
      return this.companyData.accounts.filter(
        (account) => account.category === "Private" && account.type === "TFSA"
      );
    },

    RRIF_accounts() {
      return this.companyData.accounts.filter(
        (account) => account.category === "Private" && account.type === "RRIF"
      );
    },

    private_corporation_accounts() {
      return this.companyData.accounts.filter(
        (account) =>
          account.category === "Private" && account.type === "Corporation"
      );
    },

    mortgage_company_accounts() {
      return this.companyData.accounts.filter(
        (account) => account.category !== "Private"
      );
    },

    // new accounts
    New_RRSP_accounts() {
      return this.new_accounts.filter(
        (account) => account.category === "Private" && account.type === "RRSP"
      );
    },

    New_TFSA_accounts() {
      return this.new_accounts.filter(
        (account) => account.category === "Private" && account.type === "TFSA"
      );
    },

    New_RRIF_accounts() {
      return this.new_accounts.filter(
        (account) => account.category === "Private" && account.type === "RRIF"
      );
    },

    New_private_corporation_accounts() {
      return this.new_accounts.filter(
        (account) =>
          account.category === "Private" && account.type === "Corporation"
      );
    },

    New_mortgage_company_accounts() {
      return this.new_accounts.filter(
        (account) => account.category !== "Private"
      );
    },
  },

  mounted() {
    this.getFormData();
  },
};
</script>

<style>
.bordered {
  border: 1px solid #444;
}
</style>
