<template>
  <v-form v-model="new_address_is_valid">
    <v-card>
      <v-card-title>
        {{
          adding_address ? "Adding Address" : "Updating Address"
        }}</v-card-title
      >

      <v-divider />

      <v-card-text>
        <Places @placeChanged="place_changed" />

        <v-row>
          <v-col>
            <v-text-field
              v-model="new_address_data.line1"
              label="Address Line 1"
              outlined
              hide-details="auto"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model="new_address_data.line2"
              label="Address Line 2"
              outlined
              hide-details="auto"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="3">
            <v-text-field
              v-model="new_address_data.street_number"
              label="Street Number"
              outlined
              :rules="rule.required"
              hide-details="auto"
            />
          </v-col>

          <v-col>
            <v-text-field
              v-model="new_address_data.street_name"
              label="Street Name"
              outlined
              :rules="rule.required"
              hide-details="auto"
            />
          </v-col>

          <v-col cols="4">
            <v-select
              v-model="new_address_data.street_type"
              :items="street_type_options"
              label="Street Type"
              outlined
              :rules="rule.required"
              hide-details="auto"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-select
              v-model="new_address_data.street_direction"
              :items="street_direction_options"
              label="Street Direction"
              outlined
              hide-details="auto"
            />
          </v-col>

          <v-col cols="3">
            <v-text-field
              v-model="new_address_data.unit"
              label="Unit"
              outlined
              hide-details="auto"
            />
          </v-col>

          <v-col cols="3">
            <v-text-field
              v-model="new_address_data.postal_code"
              label="Postal Code"
              outlined
              :rules="rule.required"
              hide-details="auto"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model="new_address_data.city"
              label="City"
              outlined
              :rules="rule.required"
              hide-details="auto"
            />
          </v-col>
          <v-col>
            <v-select
              v-model="new_address_data.province"
              :items="province_options"
              label="Province"
              :rules="rule.required"
              hide-details="auto"
            />
          </v-col>
        </v-row>

        <v-divider class="my-5" />

        <v-row v-if="!no_time_at_residence">
          <v-col class="grey--text"> Time At Residence </v-col>
        </v-row>

        <v-row v-if="!no_time_at_residence">
          <v-col>
            <v-text-field
              v-model="new_address_data.years"
              label="Years"
              type="number"
              outlined
              :rules="rule.number"
              hide-details="auto"
              @blur="
                new_address_data.years === ''
                  ? (new_address_data.years = null)
                  : (new_address_data.years = new_address_data.years)
              "
            />
          </v-col>

          <v-col>
            <v-text-field
              v-model="new_address_data.months"
              label="Months"
              type="number"
              outlined
              :rules="rule.month"
              hide-details="auto"
              @blur="
                new_address_data.months === ''
                  ? (new_address_data.months = null)
                  : (new_address_data.months = new_address_data.months)
              "
            />
          </v-col>
        </v-row>

        <v-row v-if="!no_time_at_residence">
          <v-col>
            <v-menu
              v-model="date_start_menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="new_address_data.date_start"
                  label="Date Start"
                  outlined
                  hide-details="auto"
                  v-bind="attrs"
                  v-on="on"
                  :rules="rule.date"
                  hint="YYYY-MM-DD format"
                  append-icon="mdi-calendar-blank"
                />
              </template>
              <v-date-picker
                no-title
                v-model="new_address_data.date_start"
                @input="date_start_menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col>
            <v-menu
              v-if="!new_address_data.present"
              v-model="date_end_menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="new_address_data.date_end"
                  label="Date End"
                  outlined
                  hide-details="auto"
                  v-bind="attrs"
                  v-on="on"
                  :rules="rule.date"
                  hint="YYYY-MM-DD format"
                  append-icon="mdi-calendar-blank"
                />
              </template>
              <v-date-picker
                no-title
                v-model="new_address_data.date_end"
                @input="date_end_menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-switch
              label="Present"
              v-model="new_address_data.present"
              hide-details="auto"
              :disabled="
                !new_address_data.present &&
                main_address_data.some((item) => item.present)
              "
              @change="
                () => {
                  if (new_address_data.present) {
                    new_address_data.date_end = null;
                  }
                }
              "
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn
          v-if="adding_address"
          :disabled="!new_address_is_valid"
          color="primary"
          @click="$emit('save_add', new_address_data)"
        >
          save
        </v-btn>

        <v-btn
          v-if="updating_address"
          :disabled="!new_address_is_valid"
          color="primary"
          @click="$emit('save_update', new_address_data)"
        >
          update
        </v-btn>

        <v-btn @click="$emit('close')"> cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import Places from "@/components/google_maps/Places.vue";

export default {
  props: {
    adding_address: {
      type: Boolean,
    },

    updating_address: {
      type: Boolean,
    },

    rule: {
      type: Object,
    },

    address_data: {
      type: Object,
    },

    main_address_data: {
      type: Array,
    },

    no_time_at_residence: {
      type: Boolean,
    },
  },

  components: {
    Places,
  },

  data: () => ({
    date_start_menu: false,
    date_end_menu: false,
    new_address_is_valid: false,

    // address options
    province_options: [
      "Alberta",
      "British Columbia",
      "Manitoba",
      "New Brunswick",
      "Newfoundland and Labrador",
      "Nova Scotia",
      "Northwest Territories",
      "Nunavut",
      "Ontario",
      "Prince Edward Island",
      "Quebec",
      "Saskatchewan",
      "Yukon",
    ],

    street_direction_options: ["N", "S", "E", "W", "NE", "NW", "SE", "SW"],

    street_type_options: [
      "Abbey",
      "Acres",
      "Allée",
      "Alley",
      "Autoroute",
      "Avenue",
      "Bay",
      "Beach",
      "Bend",
      "Boulevard",
      "By-Pass",
      "Byway",
      "Campus",
      "Cape",
      "Carré",
      "Carrefour",
      "Centre",
      "Cercle",
      "Chase",
      "Chemin",
      "Circle",
      "Circuit",
      "Close",
      "Common",
      "Concession",
      "Corners",
      "Côte",
      "Cour",
      "Cours",
      "Court",
      "Cove",
      "Crescent",
      "Croissant",
      "Crossing",
      "Cul-de-sac",
      "Dale",
      "Dell",
      "Diversion",
      "Downs",
      "Drive",
      "Éhangeur",
      "End",
      "Esplande",
      "Estates",
      "Expressway",
      "Extension",
      "Farm",
      "Field",
      "Forest",
      "Freeway",
      "Front",
      "Gardens",
      "Gate",
      "Glen",
      "Green",
      "Grounds",
      "Grove",
      "Harbour",
      "Heath",
      "Heights",
      "Highlands",
      "Highway",
      "Hill",
      "Hollow",
      "Île",
      "Impasse",
      "Inlet",
      "Island",
      "Key",
      "knoll",
      "Landing",
      "Lane",
      "Limits",
      "Line",
      "Link",
      "Lookout",
      "Loop",
      "Mall",
      "Manor",
      "Maze",
      "Meadow",
      "Mews",
      "Montée",
      "Moor",
      "Mount",
      "Mountain",
      "Orchard",
      "Parade",
      "Parc",
      "Park",
      "Parkway",
      "Passage",
      "Path",
      "Pathway",
      "Pines",
      "Place",
      "Plateau",
      "Plaza",
      "Point",
      "Pointe",
      "Port",
      "Private",
      "Promenade",
      "Quai",
      "Quay",
      "Ramp",
      "Rang",
      "Range",
      "Ridge",
      "Rise",
      "Road",
      "Rond-point",
      "Route",
      "Row",
      "Rue",
      "Ruelle",
      "Run",
      "Sentier",
      "Square",
      "Street",
      "Subdivision",
      "Terrance",
      "Terrasse",
      "Thicket",
      "Towers",
      "Townline",
      "Trail",
      "Turnabout",
      "Vale",
      "Via",
      "View",
      "Village",
      "Villas",
      "Vista",
      "Voie",
      "Walk",
      "Way",
      "Wharf",
      "Wood",
      "Wynd",
    ],

    new_address_data: {
      line1: null,
      street_number: null,
      street_name: null,
      street_direction: null,
      line2: null,
      street_type: null,
      unit: null,
      postal_code: null,
      city: null,
      province: null,
      years: null,
      months: null,
      date_start: null,
      date_end: null,
      present: false,
      archived: false,
    },
  }),

  methods: {
    // async loadGoogleMapsAPI() {
    //   const loader = new Loader({
    //     apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    //     version: "weekly",
    //     libraries: ["places"],
    //   });

    //   try {
    //     await loader.load();
    //     this.initAutocomplete();
    //   } catch (error) {
    //     console.error("Failed to load Google Maps API", error);
    //   }
    // },

    // initAutocomplete() {
    //   const autocomplete = new google.maps.places.Autocomplete(
    //     document.getElementById("autocomplete")
    //   );

    //   // Add listener to the place_changed event
    //   autocomplete.addListener("place_changed", () => {
    //     const place = autocomplete.getPlace();
    //     this.google_places = place.formatted_address; // Update the address
    //     console.log(place);

    //     this.new_address_data.street_number = place.address_components.find(
    //       (component) => component.types.includes("street_number")
    //     ).long_name;

    //     this.new_address_data.street_name = place.address_components.find(
    //       (component) => component.types.includes("route")
    //     ).short_name;

    //     this.new_address_data.city = place.address_components.find(
    //       (component) => component.types.includes("locality")
    //     ).long_name;

    //     this.new_address_data.province = place.address_components.find(
    //       (component) => component.types.includes("administrative_area_level_1")
    //     ).long_name;

    //     this.new_address_data.postal_code = place.address_components.find(
    //       (component) => component.types.includes("postal_code")
    //     ).long_name;

    //     this.new_address_data.line1 = place.formatted_address.split(",")[0];
    //   });
    // },

    place_changed(place) {
      const addressComponents = place.address_components;

      const getAddressComponent = (type) => {
        const component = addressComponents.find((component) =>
          component.types.includes(type)
        );
        return component ? component.long_name : "";
      };

      // Assign basic address components
      this.new_address_data.street_number =
        getAddressComponent("street_number");
      let streetName = getAddressComponent("route");
      this.new_address_data.city = getAddressComponent("locality");
      this.new_address_data.province = getAddressComponent(
        "administrative_area_level_1"
      );
      this.new_address_data.postal_code = getAddressComponent("postal_code");
      this.new_address_data.unit = getAddressComponent("subpremise");

      // Define street direction options in long text
      const streetDirectionOptions = [
        "North",
        "South",
        "East",
        "West",
        "Northeast",
        "Northwest",
        "Southeast",
        "Southwest",
      ];

      // Function to extract street direction and type
      const extractStreetDetails = (streetName) => {
        let direction = "";
        let type = "";

        // Check for direction at the start of the street name
        const directionPattern = new RegExp(
          `\\b(${streetDirectionOptions.join("|")})$`,
          "i"
        );
        const directionMatch = streetName.match(directionPattern);
        if (directionMatch) {
          direction = directionMatch[0];
          streetName = streetName.replace(directionPattern, "").trim();
        }

        // Check for type at the end of the street name
        const typePattern = new RegExp(
          `\\b(${this.street_type_options.join("|")})$`,
          "i"
        );

        const typeMatch = streetName.match(typePattern);
        if (typeMatch) {
          type = typeMatch[0];
          streetName = streetName.replace(typePattern, "").trim();
        }

        return { streetName, direction, type };
      };

      // Extract and assign street details
      const {
        streetName: finalStreetName,
        direction,
        type,
      } = extractStreetDetails(streetName);

      this.new_address_data.street_name = finalStreetName;
      this.new_address_data.street_type = type;

      // Assign direction from long text to short text
      const directionMap = {
        North: "N",
        South: "S",
        East: "E",
        West: "W",
        Northeast: "NE",
        Northwest: "NW",
        Southeast: "SE",
        Southwest: "SW",
      };

      this.new_address_data.street_direction = directionMap[direction] || "";

      // Construct address_line1 and address_line2
      const addressLine1Components = [
        this.new_address_data.street_number,
        this.new_address_data.street_name,
        this.new_address_data.street_direction,
        this.new_address_data.street_type,
      ]
        .filter(Boolean)
        .join(" ");

      this.new_address_data.line1 = addressLine1Components;

      this.new_address_data.line2 = this.new_address_data.unit
        ? `${this.new_address_data.unit}`
        : "";
    },
  },

  mounted() {
    if (this.updating_address) {
      this.new_address_data = this.address_data;
    }
  },
};
</script>
