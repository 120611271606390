<template>
  <div>
    <v-row>
      <v-col cols="10" class="d-flex align-center">
        <v-text-field
          class="autocomplete-input-field"
          id="autocomplete"
          label="Enter your address"
          type="text"
          v-model="address"
        />
      </v-col>

      <v-col cols="2" class="d-flex align-center">
        <v-btn
          class="mr-4"
          color="red"
          title="Click to clear"
          v-if="address.length > 0"
          @click="address = ''"
          icon
        >
          <v-icon> mdi-close </v-icon>
        </v-btn>
        <v-icon medium> mdi-map-marker-outline </v-icon>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* global google */
import { Loader } from "@googlemaps/js-api-loader";

export default {
  name: "AddressForm",
  data() {
    return {
      address: "", // Add this line
    };
  },
  mounted() {
    this.loadGoogleMapsAPI();
  },
  methods: {
    async loadGoogleMapsAPI() {
      const loader = new Loader({
        apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
        version: "weekly",
        libraries: ["places"],
      });

      try {
        await loader.load();
        this.initAutocomplete();
      } catch (error) {
        console.error("Failed to load Google Maps API", error);
      }
    },
    initAutocomplete() {
      const autocomplete = new google.maps.places.Autocomplete(
        document.getElementById("autocomplete")
      );

      // Add listener to the place_changed event
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        console.log(place);
        this.address = place.formatted_address; // Update the address
        this.$emit("placeChanged", place);
      });
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
