<template>
  <div>
    <Initial_KYC_Form
      :company_id="company_id"
      :user_id="$store.getters['Auth/getAuthUser'].id"
      :form_unique_id="form_unique_id"
      :view_type="view_type"
      v-if="form_unique_id === 'initial-kyc-2024-v1'"
    />

    <Personal_KYC_Form
      :company_id="company_id"
      :user_id="$store.getters['Auth/getAuthUser'].id"
      :form_unique_id="form_unique_id"
      :view_type="view_type"
      v-if="form_unique_id === 'personal-kyc-2024-v1'"
    />

    <Corporation_KYC_Form
      :company_id="company_id"
      :user_id="$store.getters['Auth/getAuthUser'].id"
      :form_unique_id="form_unique_id"
      :view_type="view_type"
      v-if="form_unique_id === 'corporation-kyc-2023-v1'"
    />

    <Mortgage_Company_KYC_Form
      :company_id="company_id"
      :user_id="$store.getters['Auth/getAuthUser'].id"
      :form_unique_id="form_unique_id"
      :view_type="view_type"
      v-if="form_unique_id === 'mortgage-company-kyc-2023-v1'"
    />
  </div>
</template>

<script>
import Initial_KYC_Form from "@/components/Investor_KYC/Initial";
import Personal_KYC_Form from "@/components/Investor_KYC/Personal";
import Corporation_KYC_Form from "@/components/Investor_KYC/Corporation";
import Mortgage_Company_KYC_Form from "@/components/Investor_KYC/Mortgage_Company";

export default {
  name: "ReadForm",

  components: {
    Initial_KYC_Form,
    Personal_KYC_Form,
    Corporation_KYC_Form,
    Mortgage_Company_KYC_Form,
  },

  props: ["form_unique_id", "company_id"],

  data: () => ({
    user_id: null,
    view_type: "logged_in",
  }),
};
</script>
