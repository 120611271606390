<template>
  <v-container>
    <!-- notifications -->
    <v-alert
      v-model="error"
      transition="fade-transition"
      type="error"
      style="z-index: 2; position: fixed; right: 15px"
      max-width="500px"
    >
      <v-icon class="float-right" @click="error = false">mdi-close</v-icon>
      <div class="mr-10 text-subtitle-1">
        {{ errorMessage }}
      </div>
    </v-alert>

    <v-alert
      v-model="submitted"
      transition="fade-transition"
      type="success"
      style="z-index: 2; position: fixed; right: 15px"
      max-width="500px"
    >
      <v-icon class="float-right" @click="error = false">mdi-close</v-icon>
      <div class="mr-10 text-subtitle-1">
        Successfully
        {{ viewed_as === "add" ? "Created" : "Updated" }}.
      </div>
    </v-alert>

    <!-- laoding screen -->
    <v-row v-if="loading">
      <v-col>
        <h3 class="text-center">
          <v-progress-circular indeterminate class="ml-3" color="primary" />
          Please be patient, this may take a while.
        </h3>
      </v-col>
    </v-row>

    <v-row v-if="!loading" justify="center">
      <v-col xs="12" xl="8">
        <p v-if="viewed_as !== 'kyc_add_account'" class="text-h5">
          {{ viewed_as === "add" ? "Create" : "Update" }}
          Account
        </p>

        <p
          v-if="viewed_as !== 'kyc_add_account'"
          class="text-subtitle-2 grey--text"
        >
          Before finalizing the account
          {{
            viewed_as === "add" || viewed_as === "kyc_add_account"
              ? "creation"
              : "update"
          }}
          process, review all the information you've entered carefully to ensure
          accuracy. Once you're confident that all details are correct, proceed
          with the submission to create your account successfully.
        </p>

        <v-form v-model="form_is_valid">
          <!-- category and type -->
          <v-row>
            <v-col>
              <v-select
                v-model="form_data.category"
                label="Category"
                :items="category_options"
                :rules="rule.required"
                @change="handle_change_category"
              />
            </v-col>

            <v-col>
              <v-select
                v-model="form_data.type"
                label="Type"
                :items="
                  type_options.filter((el) => {
                    const mortgage_company_categories = [
                      'Bank',
                      'Credit Union',
                      'Insurance Company',
                      'Mortgage Finance Company (MFC)',
                      'Mortgage Investment Entity (MIE)',
                      'Brokerage (Self Funding)',
                      'Trust Company',
                    ];

                    return mortgage_company_categories.includes(
                      form_data.category
                    )
                      ? el === 'Corporation'
                      : el;
                  })
                "
                :rules="rule.required"
                @change="handle_change_type"
              />
            </v-col>
          </v-row>

          <!-- name -->
          <v-row v-if="form_data.type !== 'Cash'">
            <v-col v-if="form_data.category === 'Private'">
              <v-select
                v-model="trust_company_name"
                label="Select Trust Company"
                :items="['Olympia', 'Canadian Western', 'Other']"
                :rules="rule.required"
                hide-details="auto"
                @change="handle_change_trust_company"
              />
            </v-col>

            <v-col>
              <v-text-field
                v-if="
                  trust_company_name === 'Other' ||
                  form_data.category !== 'Private'
                "
                v-model="form_data.name"
                label="Name"
                :rules="rule.required"
                hide-details="auto"
              />
            </v-col>
          </v-row>

          <!-- additional information section -->
          <v-row>
            <v-col>
              <v-expansion-panels v-model="panels" multiple>
                <v-expansion-panel class="bordered">
                  <v-expansion-panel-header>
                    <div>Additional Information</div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <v-text-field
                      v-model="form_data.account_number"
                      label="Account Number"
                      hide-details="auto"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-select
                v-model="form_data.renewal_responsibility"
                label="Renewal Responsibility"
                :items="renewal_responsibility_options"
              />
            </v-col>

            <v-col>
              <v-switch
                v-if="viewed_as !== 'kyc_add_account'"
                label="Archived"
                v-model="form_data.archived"
              />
            </v-col>
          </v-row>

          <!-- address section -->
          <v-row>
            <v-col>
              <v-expansion-panels>
                <v-expansion-panel class="bordered">
                  <v-expansion-panel-header>
                    <div>Address</div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <v-row class="mb-5">
                      <v-col cols="12" class="pb-0 mb-n2">
                        <div v-if="form_data.addresses.length > 0" class="mb-5">
                          <v-alert
                            class="mt-5"
                            color="orange darken-3"
                            dense
                            transition="fade-transition"
                            outlined
                          >
                            <div class="d-flex align-center">
                              <v-icon color="orange darken-3">mdi-information-outline</v-icon>
                              <span class="ml-2">
                                Any additional or changes in this address will be saved only after clicking the submit button.
                              </span>
                            </div>
                          </v-alert>

                          <p class="grey--text text-subtitle-2 mb-2">
                            List of added address:
                          </p>

                          <v-data-table
                            :headers="header_address"
                            item-key="order"
                            :items="
                              form_data.addresses.filter((el) => !el.archived)
                            "
                            :items-per-page="5"
                            @click:row="handle_updating_address($event)"
                            v-model="selected_address"
                            show-select
                          >
                            <template v-slot:[`item.address`]="{ item }">
                              {{ item.street_number }}
                              {{ item.street_name }}
                              {{ item.street_type }}
                              {{ item.street_direction }}
                              {{ item.unit }}
                              {{ item.city }}
                              {{ item.province }}
                              {{ item.postal_code }}
                            </template>
                          </v-data-table>

                          <v-btn
                            v-if="selected_address.length > 0"
                            @click="handle_delete_address()"
                            color="error"
                            class="mt-2"
                          >
                            delete
                          </v-btn>
                        </div>

                        <div>
                          <v-btn
                            v-if="!adding_address && !updating_address"
                            @click="adding_address = true"
                          >
                            Add Address
                          </v-btn>
                        </div>

                        <Address
                          v-if="adding_address || updating_address"
                          :adding_address="adding_address"
                          :updating_address="updating_address"
                          :address_data="new_addresses"
                          :main_address_data="form_data.addresses"
                          :no_time_at_residence="true"
                          :rule="rule"
                          @save_add="handle_save_address"
                          @save_update="handle_save_update_address"
                          @close="
                            adding_address = false;
                            updating_address = false;
                          "
                        />
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>

          <!-- note section -->
          <v-row class="mt-5">
            <v-col>
              <!-- note list -->
              <v-card style="background: transparent" ref="note_list" flat>
                <p class="text-h6 font-weight-medium">
                  Notes
                  <v-icon> mdi-note-edit-outline </v-icon>
                </p>
                <p class="mb-0 grey--text font-weight-medium">
                  Click on the text field to input your notes, you can also add
                  screenshots or image files by copy/paste or drag and drop into
                  the text field below.
                </p>

                <v-card-text>
                  <p
                    v-if="form_data.notes.length === 0"
                    class="text-center grey--text text-subtitle-1"
                  >
                    No notes available. Make a note below.
                  </p>

                  <div
                    v-for="(note, index) in form_data.notes"
                    :key="note.id"
                    class="my-2"
                  >
                    <v-card class="rounded-lg">
                      <v-card-text>
                        <p class="d-flex align-center mb-0">
                          <span>
                            <v-icon class="text-body-1 mr-1">
                              mdi-account-outline
                            </v-icon>
                            {{ note.user ? note.user.first_name : "" }}
                            {{ note.user ? note.user.last_name : "" }}
                          </span>

                          <span class="ml-auto">
                            <v-icon class="text-body-1 mr-1">
                              mdi-calendar-outline
                            </v-icon>
                            {{ note.createdAt | formatDate }}
                          </span>

                          <span class="ml-5">
                            <v-btn
                              title="Click to edit note"
                              @click="handle_edit_note(note, index)"
                              icon
                              small
                            >
                              <v-icon color="teal" class="text-body-1">
                                mdi-pencil-outline
                              </v-icon>
                            </v-btn>

                            <v-btn
                              title="Click to delete"
                              @click="handle_delete_note(index)"
                              icon
                              small
                            >
                              <v-icon color="red"> mdi-close </v-icon>
                            </v-btn>
                          </span>
                        </p>
                      </v-card-text>

                      <v-divider />

                      <v-card-text>
                        <div v-if="note.note" style="white-space: pre">
                          {{ note.note }}
                        </div>

                        <div
                          v-if="note.content"
                          class="note-contents"
                          v-html="note.content"
                        ></div>
                      </v-card-text>
                    </v-card>
                  </div>
                </v-card-text>
              </v-card>

              <v-alert
                class="mt-5"
                color="orange darken-3"
                dense
                transition="fade-transition"
                outlined
              >
                <div class="d-flex align-center">
                  <v-icon color="orange darken-3">mdi-information-outline</v-icon>
                  <span class="ml-2">
                    Any additional or changes in this notes will be saved only after clicking the submit button.
                  </span>
                </div>
              </v-alert>

              <!-- note editor -->
              <v-card
                style="background: transparent"
                ref="task_note_editor"
                flat
              >
                <v-card-text class="pa-1">
                  <div>
                    <RichTextEditor
                      ref="accountTipTapEditor"
                      type="account_note"
                      @handleSaveNote="handle_save_note"
                    />
                  </div>
                </v-card-text>
              </v-card>

              <v-dialog v-model="note_dialog" fullscreen>
                <v-toolbar class="sticky-toolbar orange darken-3" flat>
                  <v-toolbar-title>
                    <v-icon>mdi-image</v-icon> Image Preview
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="note_dialog = false" title="Close">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>

                <v-card>
                  <v-card-text>
                    <v-img :src="selectedImage" aspect-ratio="1" />
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>

          <!-- actions  -->
          <v-row>
            <v-col>
              <v-btn
                v-if="viewed_as !== 'kyc_add_account'"
                color="primary"
                @click="handle_submit_form"
                :disabled="!form_is_valid"
              >
                {{ viewed_as === "add" ? "submit" : "update" }}
              </v-btn>

              <v-btn
                v-if="viewed_as === 'kyc_add_account'"
                color="primary"
                @click="handle_save_account"
                :disabled="!form_is_valid"
              >
                Save
              </v-btn>

              <v-btn class="ml-4" @click="handle_cancel()"> cancel </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from "@/plugins/API";
import Address from "@/components/customer/AddAddress.vue";
import RichTextEditor from "@/components/note/TipTapEditor.vue";
import filt from "@/plugins/filters";

export default {
  name: "CreateAccount",

  props: {
    id: {
      type: String,
      required: true,
    },

    viewed_as: {
      type: String,
      required: true,
    },
  },

  components: {
    Address,
    RichTextEditor,
  },

  data: () => ({
    loading: false,
    error: false,
    submitted: false,
    errorMessage: "",
    form_is_valid: false,

    adding_address: false,
    updating_address: false,
    new_addresses: {},
    selected_address: [],
    header_address: [
      { text: "Address", value: "address" },
      { text: "Present", value: "present" },
    ],

    note_dialog: false,
    selectedImage: "",
    temp_note_index: null,

    panels: [],

    // item options
    type_options: ["RRSP", "TFSA", "RRIF", "Cash", "Corporation"],

    category_options: [
      "Private",
      "Bank",
      "Credit Union",
      "Insurance Company",
      "Mortgage Finance Company (MFC)",
      "Mortgage Investment Entity (MIE)",
      "Brokerage (Self Funding)",
      "Trust Company",
    ],

    renewal_responsibility_options: ["EquityApproved.ca", "Lender"],

    trust_company_name: null,

    // form data
    form_data: {
      type: null,
      category: null,
      account_number: null,
      name: null,
      corporation_name: null,
      renewal_responsibility: null,
      notes: [],
      addresses: [],
      archived: false,
    },

    rule: {
      required: [(v) => !!v || "Required."],

      number: [
        (v) =>
          v === "" || v === null || /^\d+$/.test(v) || "Input must be a number",
      ],

      month: [
        (v) =>
          v === "" || v === null || /^\d+$/.test(v) || "Input must be a number",
        (v) => (v >= 0 && v <= 11) || "Input must be between 0 and 11",
      ],

      date: [
        (v) =>
          (v && /^\d{4}-\d{2}-\d{2}$/.test(v)) ||
          "Invalid date. Format should be YYYY-MM-DD",
      ],
    },
  }),

  computed: {},

  methods: {
    scrollToElement(element) {
      window.scrollTo({
        top: element.offsetTop - 5, // adjust the scroll position by 100px
        behavior: "smooth",
      });
    },

    // method for canceling
    handle_cancel() {
      switch (this.viewed_as) {
        case "kyc_add_account":
          this.$emit("handle_cancel");
          break;

        case "update":
          this.$router.push({
            path: `/dashboard/admin/company/read/${this.form_data.company.id}`,
          });
          break;

        default:
          this.$router.push({
            path: `/dashboard/admin/company/read/${this.id}`,
          });
      }
    },

    // method for submittinng form
    async handle_submit_form() {
      try {
        this.loading = true;

        let res = null;

        if (this.viewed_as === "add") {
          res = await API().post(`api/account/create_account`, {
            company_id: this.id,
            form_data: this.form_data,
          });
        }

        if (this.viewed_as === "update") {
          res = await API().post(`api/account/update_account`, {
            account_id: this.form_data.id,
            form_data: this.form_data,
          });
        }

        if (res.status === 201) {
          this.submitted = true;
          console.log(res);

          setTimeout(() => {
            this.viewed_as === "add"
              ? this.$router.push({
                  path: `/dashboard/admin/company/read/${this.id}`,
                })
              : this.$router.go(-1);
            this.loading = false;
          }, 3000);
        } else {
          this.loading = false;
        }
      } catch (error) {
        console.log(error.response.data.message);
        this.error = true;
        this.errorMessage = error.response.data.message;
        this.loading = false;
      }
    },

    handle_save_account() {
      this.$emit("save_account", this.form_data);
    },

    // method for getting account data
    async get_account_data() {
      try {
        this.loading = true;

        const account = await API().get(
          `api/account/read_account?id=${this.id}`
        );

        if (account.status === 200) {
          this.form_data = account.data;

          let new_order = 0;
          for (const note of this.form_data.notes) {
            note.order = new_order;
            new_order++;
          }

          new_order = 0;
          for (const address of this.form_data.addresses) {
            address.order = new_order;
            new_order++;
          }

          // console.log("account data: ", this.form_data);
          this.trust_company_name = this.form_data.name;

          this.handle_change_type();
        }

        if (account.status === 204) {
          console.log("account data is not exist");
        }

        this.loading = false;
      } catch (error) {
        console.log(error.response.data.message);
        this.error = true;
        this.errorMessage = error.response.data.message;
      }
    },

    // method for change
    handle_change_category() {
      switch (this.form_data.category) {
        case "Private":
          this.form_data.type = null;
          break;

        default:
          this.form_data.type = "Corporation";
      }

      this.handle_change_type();
    },

    handle_change_type() {
      switch (this.form_data.type) {
        case "RRSP":
          if (!this.panels.includes(0)) this.panels.push(0);
          break;

        case "TFSA":
          if (!this.panels.includes(0)) this.panels.push(0);
          break;

        case "RRIF":
          if (!this.panels.includes(0)) this.panels.push(0);
          break;

        case "Cash":
          if (this.panels.includes(0))
            this.panels.splice(this.panels.indexOf(0), 1);
          break;

        default: {
          if (this.panels.includes(0))
            this.panels.splice(this.panels.indexOf(0), 1);
        }
      }
    },

    handle_change_trust_company() {
      const olympia_address = {
        line1: null,
        street_number: "P.O. BOX 2581",
        street_name: "STN Central",
        street_direction: null,
        line2: null,
        street_type: null,
        unit: null,
        postal_code: "T2P 1C8",
        city: "Calgary",
        province: "Alberta",
        present: true,
        unique_id: "olympia_address",
      };

      const canadian_western_address = {
        line1: null,
        street_number: "300 750",
        street_name: "Cambie Street",
        street_direction: null,
        line2: null,
        street_type: null,
        unit: null,
        postal_code: "V6B 0A2",
        city: "Vancouver",
        province: "British Columbia",
        present: true,
        unique_id: "canadian_western_address",
      };

      switch (this.trust_company_name) {
        case "Olympia": {
          // check if canadian western is in the list
          const index = this.form_data.addresses.findIndex(
            (el) => el.unique_id === "canadian_western_address"
          );

          // remove canadian western if it is in the list
          if (index !== -1) this.form_data.addresses.splice(index, 1);

          this.form_data.name = this.trust_company_name;
          this.handle_save_address(olympia_address);
          break;
        }

        case "Canadian Western": {
          // check if olympia is in the list
          const index = this.form_data.addresses.findIndex(
            (el) => el.unique_id === "olympia_address"
          );

          // remove olympia if it is in the list
          if (index !== -1) this.form_data.addresses.splice(index, 1);

          this.form_data.name = this.trust_company_name;
          this.handle_save_address(canadian_western_address);
          break;
        }

        case "Other": {
          const olympia_index = this.form_data.addresses.findIndex(
            (el) => el.unique_id === "olympia_address"
          );

          const canadian_western_index = this.form_data.addresses.findIndex(
            (el) => el.unique_id === "canadian_western_address"
          );

          if (olympia_index !== -1)
            this.form_data.addresses.splice(olympia_index, 1);
          if (canadian_western_index !== -1)
            this.form_data.addresses.splice(canadian_western_index, 1);

          this.form_data.name = null;
        }
      }
    },

    // method for notes
    handle_save_note(content, is_edit) {
      if (is_edit) {
        this.form_data.notes[this.temp_note_index].content = content;
        this.temp_note_index = null;

        const note_list = this.$refs.note_list.$el;
        this.scrollToElement(note_list);
      } else {
        this.form_data.notes.push({
          note: "",
          content: content,
          user: {
            id: this.$store.getters["Auth/getAuthUser"].id,
            first_name: this.$store.getters["Auth/getAuthUser"].first_name,
            last_name: this.$store.getters["Auth/getAuthUser"].last_name,
          },
          createdAt: new Date().toISOString(),
        });
      }
    },

    handle_delete_note(index) {
      if (window.confirm("Are you sure you want to delete this note?")) {
        this.form_data.notes.splice(index, 1);
      } else {
        console.log("Delete cancelled");
        return;
      }
    },

    handle_edit_note(note, index) {
      this.temp_note_index = index;

      // set the editor content
      this.$refs.accountTipTapEditor.setContentToEdit(note);

      const task_note_editor = this.$refs.task_note_editor.$el;
      this.scrollToElement(task_note_editor);
    },

    onNoteImageClick(event) {
      if (event.target.tagName === "IMG") {
        this.selectedImage = event.target.src;
        this.note_dialog = true;
      }
    },

    // address methods
    handle_save_address(data) {
      let new_order = 0;

      if (this.form_data.addresses.length === 0) {
        new_order = new_order + 1;
      } else {
        // get the heighest order then add 1
        const highest_order = Math.max.apply(
          Math,
          this.form_data.addresses.map((item) => item.order)
        );
        new_order = highest_order + 1;
      }

      data.order = new_order;

      this.form_data.addresses.push(data);
      this.adding_address = false;
    },

    handle_save_update_address(data) {
      const index = this.form_data.addresses.findIndex(
        (item) => item.order === data.order
      );

      this.form_data.addresses.splice(index, 1, data);

      this.updating_address = false;
    },

    handle_updating_address(data) {
      if (this.adding_address) {
        alert(
          "You are currently adding a new address. Please finish adding it first before updating an existing address."
        );

        return;
      } else {
        this.updating_address = true;

        this.new_addresses = data;
      }
    },

    handle_delete_address() {
      if (window.confirm("Are you sure you want to delete this?")) {
        this.selected_address.forEach((el) => {
          const index = this.form_data.addresses.findIndex(
            (item) => item.order === el.order
          );

          this.form_data.addresses.splice(index, 1);
        });

        this.selected_address = [];
      } else {
        console.log("deleting address was canceled.");
        this.selected_address = [];
      }
    },
  },

  mounted() {
    if (this.viewed_as === "update") this.get_account_data();

    this.$nextTick(() => {
      if (this.$refs.note_list) {
        this.$refs.note_list.$el.addEventListener(
          "click",
          this.onNoteImageClick
        );
      }
    });
  },
  
  filters: {
    formatDate: filt.formatDate,
  },
};
</script>

<style lang="scss">
.bordered {
  border: 1px solid #3c3c3c !important;
  background-color: transparent !important;
}

.note-contents {
  p {
    img {
      max-width: 100%;  
      height: auto;
      cursor: pointer;
    }
  }
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #444;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #444;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}
</style>
